.notification-container {
    display: flex;
    padding: 0px;
    height: 116px;
    max-width: 440px;
    // width: 440px;
    // font-family: 'Inter';

    @media only screen and (max-width: 720px) {
        width: 100%;
    }

    .message-container {
        margin-top: 0px;
        padding: 10px;
        width: 100%;
        max-width: 360px;
        overflow-y: auto;

        .title-container {
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            color: #101828;
        }

        .message {
            font-weight: 400;
            color: #667085;
        }

        .dismiss {
            margin-top: 20px;
            font-weight: 500;
            color: #667085;
        }

        .date {
            margin-top: 10px;
            font-weight: 400;
            color: #667085;
            font-size: 12px;
        }

    }
}

.css-87haeo-MuiPaper-root-MuiPopover-paper {
    border-radius: 8px;
}