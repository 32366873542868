.developer-customer-container {

    // Applied to all form
    .MuiFormHelperText-root {
        margin-left: 0px;
        margin-top: 4px;
    }

    @media only screen and (max-width: 720px) {
        margin-left: 16px;
        margin-right: 16px;
    }

    @media only screen and (min-width: 720px) {
        margin-left: 32px;
        margin-right: 32px;
    }

    .MuiTableContainer-root {
        box-shadow: none;
        border: 1px solid #EAECF0;
    }

    .upper-panel {
        margin-bottom: 20px;

        @media only screen and (max-width: 720px) {
            flex-direction: column;

            .input-search-panel {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
            }

            .create-new-btn {
                border-radius: 8px;
                height: 40px;
                font-size: 14px;
                font-weight: 500;
                text-transform: none;
                background-color: #43D9BB;

                &:hover {
                    box-shadow: none;
                    background-color: #36b299;
                }
            }
        }

        @media only screen and (min-width: 720px) {

            .input-search-panel {
                gap: 6px;

                .search-group {
                    display: flex;
                    gap: 4px;

                    .search-btn {
                        text-transform: none;
                        height: 40px;
                        background: #FFFFFF;
                        /* Gray/300 */

                        border: 1px solid #D0D5DD;
                        /* Shadow/xs */

                        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                        border-radius: 8px;
                    }

                    .groupButton {
                        @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 capitalize p-5 text-gray-500 font-normal;

                        @media only screen and (max-width: 720px) {
                            width: 100%;
                        }
                    }

                    .groupButton:hover {
                        @apply bg-gray-100 shadow-none;
                    }
                }
            }

            .create-new-btn {
                border-radius: 8px;
                height: 40px;
                font-size: 14px;
                font-weight: 500;
                text-transform: none;
                background-color: #43D9BB;

                &:hover {
                    box-shadow: none;
                    background-color: #36b299;
                }
            }
        }

        .input-api {
            width: 320px;
            height: 40px;

            @media only screen and (max-width: 720px) {
                width: 100%;
            }

            .MuiOutlinedInput-root {
                border-radius: 8px;
            }
        }
    }

    .table-content {
        margin-top: 20px
    }

    .customer {
        display: flex;
        gap: 10px;
        @apply text-lg font-medium;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
    }

    .groupButton {
        display: flex;
        gap: 9.67px;
        @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 text-gray-800 font-medium;
        width: 105px;
        text-transform: none;
        height: 40px !important;
        background: #FFFFFF;

        @media only screen and (max-width: 720px) {
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
        }
    }



    .exportBtn {
        display: flex;
        gap: 9.67px;
        @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 text-gray-800 font-medium;
        width: 105px;
        text-transform: none;
        height: 40px !important;
        background: #FFFFFF;

        @media only screen and (max-width: 720px) {
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
        }
    }

    .groupButton:hover {
        @apply bg-gray-100 shadow-none;
    }

    .customer-content {
        @media only screen and (min-width: 720px) {
            padding-bottom: 150px;
        }
    }

}

.customer-details-container {
    width: 100%;
    margin-top: 132px;
    padding-left: 32px;
    padding-right: 32px;

    .headings {
        margin-top: 0;
    }

    @media only screen and (max-width: 720px) {
        margin-top: 0px;
        padding-left: 16px;
        padding-right: 16px;
        .headings {
            margin-top: 0;
        }
    }

    .customer {
        @apply text-lg font-medium flex;
        gap: 10px;
        align-items: center;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
    }

    .basic-details {

        .headings {
            @apply text-base font-normal text-gray-500
        }

        .first-row {
            @apply font-semibold text-4xl;
        }

        .second-row {
            @apply font-medium text-base;
        }

        .third-row {
            @apply font-normal text-base;
        }
    }

    .basic-details-second {

        .headings {
            @apply text-base font-normal text-gray-500
        }

        .first-row {
            @apply font-semibold text-4xl;
        }

        .second-row {
            @apply font-medium text-base;
        }

        .third-row {
            @apply font-normal text-base;
        }

        @media only screen and (min-width: 720px) {
            padding-bottom: 150px;
        }
    }

    .form-control-text {
        margin-bottom: 4px;
    }

    .MuiOutlinedInput-root {
        border-radius: 8px;
    }

    .Mui-disabled {
        background-color: #E0E0E0;
        border-radius: 8px;
    }

}

#menu-country {
    .MuiPaper-elevation {
        height: 25vh;
        min-width: 100%;
    }
}

#menu-state {
    .MuiPaper-elevation {
        height: 25vh;
        min-width: 100%;
    }
}

.dev-customer-details-root {
    width: 100%;
}