.DARK-reporting {
    .MuiCard-root {
        background: rgba(9, 11, 17, 0.5) !important;
        border: 1px solid #28303F !important;
    }

    .title, .reporting, .dev-reporting {
        @apply text-lg font-medium text-white;
    }
    .inner-title {
        @apply text-base font-medium text-white;
    }
    .inner-value {
        @apply text-base font-medium text-gray-900;
    }

    .standard-search-btn {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: none !important;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        color: white !important;
    }

    .date-range > .date-picker-input {
  
        @media only screen and (min-width: 720px) {
          width: 150px;
            
            .MuiInputBase-root {
                padding-right: 0px;
                background: rgba(9, 11, 17, 0.5);
                border: 1px solid #28303F;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            }
            .MuiOutlinedInput-input {
                @apply text-white;
                background: transparent;
            }
            .MuiSvgIcon-root {
                svg path {
                    stroke: #FFFFFF;
                }
            }
        }
      
        @media only screen and (max-width: 720px) {
            width: 50%;
            .MuiInputBase-root {
                padding-right: 0px;
                background: rgba(9, 11, 17, 0.5);
                border: 1px solid #28303F;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            }
            .MuiOutlinedInput-input {
                @apply text-white;
                background: transparent;
            }
            .MuiSvgIcon-root {
                svg path {
                    stroke: #FFFFFF;
                }
            }
        }
    }

    .divider {
        margin-top: 20px;
        margin-bottom: 24px;
        background: #28303F;
    }
}