.DEFAULT-transaction-txtype {
    @apply text-sm font-semibold text-gray-900;
}

.DARK-transaction-txtype {
    @apply text-sm font-semibold text-gray-500;
}

.DEFAULT-transaction-info {
    @apply text-sm font-semibold;
}

.DARK-transaction-info {
    @apply text-sm font-semibold text-white;
}

.DEFAULT-date-picker {
    .date-range > .date-picker-input {
  
        @media only screen and (min-width: 720px) {
          width: 150px;
      
          .MuiInputBase-root {
            padding-right: 0px;
          }
        }
      
        @media only screen and (max-width: 720px) {
          width: 50%;
        }
    }
}

.DARK-date-picker {
    .date-range > .date-picker-input {
  
        @media only screen and (min-width: 720px) {
          width: 150px;
            
            .MuiInputBase-root {
                padding-right: 0px;
                background: rgba(9, 11, 17, 0.5);
                border: 1px solid #28303F;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            }
            .MuiOutlinedInput-input {
                @apply text-white;
                background: transparent;
            }
            .MuiSvgIcon-root {
                svg path {
                    stroke: #FFFFFF;
                }
            }
        }
      
        @media only screen and (max-width: 720px) {
            width: 50%;
            .MuiInputBase-root {
                padding-right: 0px;
                background: rgba(9, 11, 17, 0.5);
                border: 1px solid #28303F;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            }
            .MuiOutlinedInput-input {
                @apply text-white;
                background: transparent;
            }
            .MuiSvgIcon-root {
                svg path {
                    stroke: #FFFFFF;
                }
            }
        }
    }
}

.DEFAULT-divider-spacer {
    margin-top: 20px;
    margin-bottom: 24px;
}

.DARK-divider-spacer {
    margin-top: 20px;
    margin-bottom: 24px;
    background: #28303F;
}

.DEFAULT-dashboard-replacement {
    .title {
        @apply text-lg font-medium text-gray-900;
    }
    .inner-title {
        @apply text-base font-medium text-gray-900;
    }
    .inner-value {
        @apply text-base font-medium text-gray-900;
    }
    .about {
        @apply text-base font-medium text-gray-900;
    }
    .tda-payment, .twa-payment {
        @apply text-base font-medium text-gray-900;
    }
    .tda-value, .twa-value {
        @apply text-3xl font-semibold text-gray-900;
    }
}

.DARK-dashboard-replacement {
    .title {
        @apply text-lg font-medium text-white;
    }
    .inner-title {
        @apply text-base font-medium text-white;
    }
    .inner-value {
        @apply text-base font-medium text-gray-900;
    }
    .MuiCard-root {
        background: rgba(9, 11, 17, 0.5);
        border: 1px solid #28303F;
    }

    .about {
        @apply text-base font-medium text-white;
    }

    .tda-payment, .twa-payment {
        @apply text-base font-medium text-white;
    }
    .tda-value, .twa-value {
        @apply text-3xl font-semibold text-white;
    }
}

.DEFAULT-business-details-replacement {
    .business-details {
        @apply text-lg font-medium text-gray-900;
    }
    .subheadings {
        @apply font-medium text-sm text-gray-700;
    }
    .MuiFormHelperText-root{
        margin-left: 0px;
    }
    .input-field {
        .MuiOutlinedInput-root {
            border-radius: 8px;
        }
    }
    .input-description {
        .MuiOutlinedInput-root {
            border-radius: 8px;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    .divider-spacer {
        margin-top: 20px;
        margin-bottom: 24px;
    }
}

.DARK-business-details-replacement {
    .business-details {
        @apply text-lg font-medium text-white;
    }
    .subheadings {
        @apply font-medium text-sm text-gray-500;
    }
    .input-field {
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
        }
        .MuiInputBase-input {
            background: rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            border: 1px solid #28303F !important;
            color: #FFFFFF;
        }
        .MuiInputBase-input:hover {
            border: 1px solid white !important;
        }
        .MuiInputBase-input:focus {
            background: linear-gradient(#090B11, #090B11) padding-box,
            linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
            border-radius: 10px;
            border: 1px solid transparent !important;       
        }
    }
    .input-description {
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
        }
        .MuiOutlinedInput-root {
            border-radius: 8px;
            padding: 0;
            border: 1px solid transparent;
            background-color: transparent;
        }
        .MuiInputBase-input {
            padding: 8.5px 14px;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            border: 1px solid #28303F !important;
            color: #FFFFFF;
        }
        .MuiInputBase-input:hover {
            border: 1px solid white !important;
        }
        .MuiInputBase-input:focus {
            background: linear-gradient(#090B11, #090B11) padding-box,
            linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
            border-radius: 10px;
            border: 1px solid transparent !important;       
        }
    }

    .MuiFormHelperText-root{
        margin-left: 0px;
    }

    .divider-spacer {
        margin-top: 20px;
        margin-bottom: 24px;
        background: #28303F;
    }
}

.DEFAULT-transaction-listing {
    .filterBtn {
        @apply font-medium border-solid border border-gray-300 text-gray-700 h-10 bg-gray-25 rounded-lg;
        padding: 10px 16px;
        text-transform: none;
        width: 97px;
        height: 40px !important;

        &:hover {
            @apply bg-gray-100 shadow-none border-gray-300
        }

        background: #FFFFFF;
    }
}

.DARK-transaction-listing {
    .transaction {
        @apply text-lg font-medium text-white;
    }
    .default-active {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
    }
    .filterBtn {
        @apply text-white font-medium rounded-lg;
        background: transparent;
        border: 1px solid #28303F !important;
        padding: 10px 16px;
        text-transform: none;
        width: 97px;
        height: 40px !important;

        &:hover {

        }
    }
    .filterBtn:hover {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
    }
    .filterBtn:focus {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
    }
    ._active {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
    }
    .exportBtn {
        @apply text-white;
        background: transparent !important;
        border: 1px solid #28303F;

        svg path {
            stroke: #FFFFFF;
        }
    }
    .exportBtn:hover {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: 1px solid #28303F;
    }
}

