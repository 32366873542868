@media only screen and (min-width: 720px) {
    .reset-password-wrapper {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .reset-logo-wrapper {
            margin-top: 147px;
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            .circular-bordered {
                background: #C8DBF9;
                border: 10px solid #D7E7FF;
                border-radius: 28px;
                height: 56px;
                width: 56px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 21px;
                    height: 21px;
                }
            }
        }

        .reset-form-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 360px;
            
            .reset-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 24px;
                gap: 12px;
            }
            .form-mode {
                margin-top: 24px;
            }
            .reset-wrapped-input {
                display: flex;
                flex-direction: column;
                width: 360px;
                .text-input-title {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 6px;
                }
                .hide-adorment {
                    position: absolute;
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    padding: 16px 14px;
                    pointer-events: none;

                    img {
                        pointer-events: fill;
                        cursor: pointer;
                    }
                }
            }

            .help-resend {
                margin-bottom: 32px;
                .resend-text {
                    cursor: pointer;
                }
            }
        
            .MuiInputBase-root {
                box-sizing: border-box;
                border-radius: 8px;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                padding: 0;
            }
            .MuiFormHelperText-root {
                margin: 0;
                padding-top: 6px;
                padding-bottom: 0px;
                height: auto;
                line-height: 1.05rem;
            }

            .MuiOutlinedInput-input {
                padding: 10px 14px;
            }
            .btn-padding {

                width: 100%;
                margin-top: 24px;
                margin-bottom: 20px;
                .btn {
                    height: 44px;
                    text-transform: none;
                }

                .on-default-state {
                    background: #AAF0E2;
                }
                .on-invalid-state {
                    background: #43D9BB;
                }
            }

            .back-btn {
                display: flex;
                justify-content: center;
                gap: 10px;
                cursor: pointer;
            }

        }
    }
}

@media only screen and (max-width: 720px) {
    .reset-password-wrapper {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .reset-logo-wrapper {
            margin-top: 147px;
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            .circular-bordered {
                background: #C8DBF9;
                border: 10px solid #D7E7FF;
                border-radius: 28px;
                height: 56px;
                width: 56px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 21px;
                    height: 21px;
                }
            }
        }

        

        .reset-form-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            .reset-info {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 12px;
                text-align: center;
            }
            form {
                width: inherit;
            }

            .reset-wrapped-input {
                padding-left: 16px;
                padding-right: 16px;
                margin-top: 24px;
                .text-input-title {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 6px;
                }
                .hide-adorment {
                    position: absolute;
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    padding: 16px 14px;
                    pointer-events: none;

                    img {
                        pointer-events: fill;
                        cursor: pointer;
                    }
                }
            }
        
            .MuiInputBase-root {
                box-sizing: border-box;
                border-radius: 8px;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                padding: 0;
            }
            .MuiFormHelperText-root {
                margin: 0;
                padding-top: 6px;
                padding-bottom: 0px;
                height: auto;
                line-height: 1.05rem;
            }

            .MuiOutlinedInput-input {
                padding: 10px 14px;
            }
            .btn-padding {
                margin-top: 24px;
                margin-bottom: 20px;
                .btn {
                    height: 44px;
                    text-transform: none;
                }

                .on-default-state {
                    background: #AAF0E2;
                }
                .on-invalid-state {
                    background: #43D9BB;
                }
            }

            .back-btn {
                display: flex;
                justify-content: center;
                gap: 10px;
                cursor: pointer;
            }

        }
    }
}
.reset-password-wrapper-small {
    margin-left: 30px;
    margin-right: 30px;
}
