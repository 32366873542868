.personal-info {
    @media only screen and (min-width: 720px) {
        width: 500px;
    }
    @media only screen and (max-width: 720px) {
        width: 100%;
    }
    .section-one {
        padding-top: 0px;
    }
    .section-two {
        border-bottom: 0px;
    }

    #input-container {
        display: flex;
        flex-direction: column;
        width: 50%;
    
        .upper-label {
            margin-bottom: 8px;
    
            &.special {
                color: rgba(158, 158, 158, 1);
            }
    
            .aesterisk {
                color: red;
            }
        }

        .MuiInputBase-root {
            border-radius: 8px;
        }
    
        .shorter-input {
            width: 100%;
    
            .MuiOutlinedInput-root {
                height: 44px;
                border-radius: 8px;
                box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.05);
            }
        }
    
        .full-width-textfield {
            .MuiOutlinedInput-root {
                height: 44px;
                border-radius: 8px;
            }
        }
    
        .MuiOutlinedInput-root.Mui-disabled {
            .MuiOutlinedInput-input.Mui-disabled {
                background: rgba(204, 204, 204, 0.5);
                border-radius: 10px;
            }
        }
    
        .disabled {
            color: rgba(204, 204, 204, 0.5);
        }
    }
}