.case_timeout {
    display: none;
}
.snacky {
    z-index: 9999999;
    position: absolute;
    /* top: 0; */
    width: 100%;
    height: 100%;
    top: 0;
    pointer-events: none;
    overflow: hidden;

    &_isNotMain {
        @media only screen and (min-width: 720px) {
            width: calc(100vw - 282px);
        }
    }

    &_isMain {
        width: 100%;
        justify-content: center;
        margin-top: 32px !important;
    }

    &_position {

        @media only screen and (min-width: 720px) {
            padding-left: 32px;
            padding-right: 32px;
        }
        @media only screen and (max-width: 720px) {
            padding-left: 16px;
            padding-right: 16px;
        }

        display: flex;
        margin-top: 106px;

        &_message_box_success {
            pointer-events:all;
            width: 768px;
            @media only screen and (min-width: 720px) {
                height: 76px;
            }
            /* Error/25 */
            background: #F6FEF9;
            border: 1px solid #6CE9A6;

            border-radius: 8px;
            padding: 16px;
        }
        &_message_box_error {
            pointer-events:all;
            width: 768px;
            @media only screen and (min-width: 720px) {
                height: 76px;
            }
            /* Error/25 */
            background: #FFFBFA;
            border: 1px solid #FDA29B;

            border-radius: 8px;
            padding: 16px;
        }

        &_on_auth {
            width: 100%;
        }

        &_on_nulled_reason {
            height: 52px;
        }

        &_content {
            width: 100%;
            display: flex;
            flex-direction: column;

            &_title {
                display: flex;
                align-items: center;


                &_icon_sign {
                    @media only screen and (max-width: 720px) {
                        width: 8%;
                    }
                    width: 4%;
                    img {
                        width: 16.67px;
                        height: 16.67px;
                    }
                }

                &_close_btn {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                    
                    img {
                        cursor: pointer;
                        width: 10px;
                        height: 10px;
                    }
                }
            }
            &_reason {
                display: flex;
                align-items: center;

                &_spacer {
                    @media only screen and (max-width: 720px) {
                        width: 8%;
                    }
                    width: 4%;
                }
            }
        }
    }
}

.case_error_title {
    @apply text-sm font-medium text-error-700;
}

.case_success_title {
    @apply text-sm font-medium text-success-600;
}

.case_error_reason {
    @apply text-sm font-normal text-error-600 w-fit;
}
.case_success_reason {
    @apply text-sm font-normal text-success-600 w-fit;
}