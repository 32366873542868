.DEFAULT-sidebar-bg {
    background-color: #ffffff;
}
.DARK-sidebar-bg {
    background-color: #090B11;
}

.DEFAULT-menu-property {
    color: #667085;

    &.selected {
        background: #EDFFFB;
        color: #43D9BB;
    }

    // &.selected: hover {
    //     background: linear-gradient(180deg, #76D1BF 0%, rgba(118, 209, 191, 0.73) 100%);
    //     color: #ffffff;
    // }
}

.DARK-menu-property {
    color: #667085;
    
    &:hover {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%);
        border-radius: 6px;
        transition: opacity 0.5s linear;
        @apply text-white;

        svg path {
            @apply stroke-white;
        }
        animation: translate-left-out 0.3s 1 cubic-bezier(1, 1, 1, 1);
        @keyframes translate-left-out {
            0%   { transform:translateX(0%); }
            100% { transform:translateX(5px); }
        }
        transform:translateX(5px);
    }
    &.selected {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%);
        @apply text-white;

        svg path {
            @apply stroke-white;
        }
    }

    svg path {
        stroke: #667085;
    }
    // &.selected: hover {
    //     background: linear-gradient(180deg, #76D1BF 0%, rgba(118, 209, 191, 0.73) 100%);
    //     color: #ffffff;
    // }
}

.DEFAULT-sidebar-bottom-divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #EAECF0;
}

.DARK-sidebar-bottom-divider {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%);
}

.DEFAULT-username {
    @apply text-sm font-medium text-gray-700;
}

.DARK-username {
    @apply text-sm font-medium text-white;
}