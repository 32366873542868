.DEFAULT_reset_password {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    .wrapped {
        width: 360px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .about_block {
            margin-top: 147px;
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .circular_bordered {
                background: #C8DBF9;
                border: 10px solid #D7E7FF;
                border-radius: 28px;
                height: 56px;
                width: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .text_focused {
                @apply text-3xl font-semibold text-gray-900;
                margin-top: 24px;
            }
            .text_details {
                @apply text-base font-normal text-gray-400 text-center;
                margin-top: 12px;
            }
        }
        .skip {
            margin-top: 32px;
            @apply text-base font-normal text-gray-400 text-center;
            cursor: pointer;
        }
        .form_block {
            display: flex;
            flex-direction: column;
            gap: 24px;
            .input_container {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }
            .title_label {
                @apply text-sm font-medium text-gray-700;
            }
            .hide-adorment {
                position: absolute;
                display: flex;
                width: 100%;
                justify-content: flex-end;
                padding: 16px 14px;
                pointer-events: none;

                img {
                    pointer-events: fill;
                    cursor: pointer;
                }
            }
            .MuiOutlinedInput-root {
                padding-left: 0px;
                padding-right: 0px;
            }

            .MuiSvgIcon-root {
                height: 18px;
            }

            .MuiInputBase-input {
                padding: 8.5px 36px 8.5px 14px;
                box-sizing: border-box;
                height: 44px;
            }
            .MuiInputBase-root {
                box-sizing: border-box;
                border-radius: 8px;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                background-color: #FFFFFF;
            }
            .MuiFormHelperText-root {
                margin: 0;
                padding-top: 6px;
                padding-bottom: 0px;
                height: auto;
                line-height: 1.05rem;
            }

            .btn {
                @apply text-base font-medium text-white;
                background: #43D9BB !important;
                text-transform: none;
                &:hover {
                    background-color: #43D9BB !important;
                }
            }
        }
    }
    .footer_block {
        @apply text-base font-normal text-gray-400;
        margin-bottom: 32px;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}


.DARK_reset_password {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/images/common/bg-logged-in.svg');
    background-size: cover;
    overflow-y: scroll;
    .wrapped {
        width: 360px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .about_block {
            margin-top: 147px;
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .circular_bordered {
                background: #C8DBF9;
                border: 10px solid #D7E7FF;
                border-radius: 28px;
                height: 56px;
                width: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .text_focused {
                @apply text-3xl font-semibold text-white;
                margin-top: 24px;
            }
            .text_details {
                @apply text-base font-normal text-gray-500 text-center;
                margin-top: 12px;
            }
        }
        .skip {
            margin-top: 32px;
            @apply text-base font-normal text-gray-500 text-center;
            cursor: pointer;
        }
        .form_block {
            display: flex;
            flex-direction: column;
            gap: 24px;
            .input_container {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }
            .title_label {
                @apply text-sm font-medium text-gray-500;
            }
            
            .input_block {
                position: relative;
                width: 360px;
                .hide-adorment {
                    position: absolute;
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    padding: 16px 14px;
                    pointer-events: none;
    
                    img {
                        pointer-events: fill;
                        cursor: pointer;
                    }
                }
                .MuiOutlinedInput-notchedOutline {
                    border-color: transparent !important;
                }
                .MuiOutlinedInput-root {
                    border-radius: 8px !important;
                    border: 1px solid #28303F;
                    background: rgba(0, 0, 0, 0.2);
                    padding: 0;
                }
                .MuiOutlinedInput-root:focus-within {
                    background: linear-gradient(#090B11, #090B11) padding-box,
                    linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box !important;
                    border-radius: 10px;
                    border: 1px solid transparent !important; 
                }
                .MuiInputBase-input {
                    padding: 8.5px 36px 8.5px 14px;
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 8px;
                    // border: 1px solid transparent !important;
                    color: #FFFFFF;
            
                    &:-webkit-autofill {
                        transition-delay: 9999s
                    }
                }
                .MuiInputBase-root {
                    background: none !important;
                }
                .MuiFilledInput-root {
                    background-color: #43D9BB !important;
                }
            }

            .MuiFormHelperText-root {
                text-align: justify;
                margin: 0;
                padding-top: 6px;
                padding-bottom: 0px;
                height: auto;
                line-height: 1.05rem;
            }

            .btn {
                line-height: 0;
                height: 44px;
                background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
                border: none !important;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                color: white !important;
            }
        }
    }
    .footer_block {
        @apply text-base font-normal text-gray-400;
        margin-bottom: 32px;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}
