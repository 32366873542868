.developer-tools-wrap {
    width: 100%;

    @media only screen and (min-width: 720px) {
        // padding-left: 32px;
        // padding-right: 32px;
    }

    @media only screen and (max-width: 720px) {
        // padding-left: 16px;
        // padding-right: 16px;
    }

    .MuiPaper-root {
        box-shadow: none;
        border: 1px solid #EAECF0;
    }

    .webhook-wrap {
        margin-top: 162px;
    }

    .apilogging-wrap {

    }
}