.inject {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1px);

    svg {
        margin-top: 40%;
    }

    @media only screen and (min-width: 720px) {
        width: calc(100vw - 260px);
        height: 130%;
    }

    @media only screen and (max-width: 720px) {
        width: 100%;
        height: 100%
    }
}