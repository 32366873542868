@use "../SecuroButtons/SecuroButtonWithLoader/style.scss";
@import "../../root/variables.scss";

.non-bordered-spacing {
  width: 100%;
}
.root-card-shadow-none {
  box-shadow: none;
}
.MuiPaper-root {
  border: 1px solid #EAECF0;
  box-shadow: none;
}
.pill-table-row {
	background-color: $white;
	padding: 1rem 2rem;
	width: 100%;
  align-items: center;
  border-bottom: 1px solid #EAECF0;

  @media only screen and (max-width: 720px) {
    padding: 1rem 1rem;
  }

}

.list-of-options {
  @media only screen and (min-width: 720px) {
    max-width: 50vw;
    width: calc(100% - 32px);
    flex-flow: row wrap;
  }

  @media only screen and (max-width: 720px) {
    flex-flow: column wrap;
  }
  display: flex;

  .option {

    @media only screen and (min-width: 720px) {
      flex-basis: 50%;
      flex-grow: 0;
      max-width: 50%;
    }
    @media only screen and (max-width: 720px) {
      flex-flow: column wrap;
    }

    .flex-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      @media only screen and (min-width: 720px) {
        padding-right: 32px;
      }
    }
  }
}

.pill-table-header--text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $grey-500;
}

.button-action {
  background-color: #43D9BB !important;
  text-transform: none;
  border-radius: 8px;
  padding: 10px 16px;
}

.is-copy {
  width: 140px;
  height: 44px;
}

.ip-table {
  margin: auto 1rem;
}

.card {
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
  gap: 16px;
}

.btn-outline {
  text-transform: none;
  padding: 10px 16px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
}

.btn-warning {
  &:disabled {
    background: #b1241a !important;
  }
  text-transform: none;
  background: #D92D20 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.btn-confirm {
  text-transform: none;
  background: #43D9BB !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.input-custom-field {

  .MuiOutlinedInput-root {
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFFFFF;
    height: 44px;
  }

}
.pair-wise {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.key-pair {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.text-label {
  color: #344054;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.2rem;
}

.api-field {
  
  .MuiOutlinedInput-root {
    height: 44px;
    border-radius: 8px;

    .MuiOutlinedInput-input {
      background-color: #FFFFFF;
    }
  }
}

@media only screen and (max-width: 720px) {
  .edit-icon {

  }
}

#api-permissions {
  margin-bottom: 6px;
}

.btn-area {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .btn-group {
    display: flex;
    gap: 12px;

    .cancel-btn {
      @apply text-sm text-gray-700;
      border-radius: 8px;
      text-transform: none;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border: 1px solid #D0D5DD;
      padding: 10px 16px;

      width: 79px;
      height: 40px;
    }
  }
}