@import "./assets/styles/font.scss";
@import "./assets/styles/theme.scss";
@import "./assets/styles/theme-sidebar.scss";
@import "./assets/styles/theme-menu-selections.scss";
@import "./assets/styles/theme-investment-portal.scss";
@import "./assets/styles/theme-developer-portal.scss";
@import "./assets/styles/theme-transaction-listing.scss";
@import "./assets/styles/theme-customer.scss";
@import "./assets/styles/theme-reporting.scss";
@import "./assets/styles/theme-developer-tools.scss";
@import "./assets/styles/theme-account.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code, kbd, samp, pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 3px; /* 纵向滚动条*/
  height: 5px; /* 横向滚动条 */
  background-color: #667085;
}

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  background-color: #43D9BB;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.7);
}

.global-bottom-spacer {
  height: 100px;
  width: 100%;
}

.asterisk {
  color: red;
}

@media only screen and (min-width: 720px) {
  .global-header-spacer {
    height: 132px;
    width: 100%;
  }
  .global-header-spacer-child {
    height: 100px;
    width: 100%;
  }
}

.status-code-bounded-success {
  @apply h-4.5 bg-success text-center rounded-md w-fit flex justify-center items-center text-xs font-medium text-success-700;
  padding: 2px 10px;
}
.status-code-bounded-error {
  @apply h-4.5 bg-error text-center rounded-md w-fit flex justify-center items-center text-xs font-medium text-error-700;
  padding: 2px 10px;
}
.status-code-bounded-neutral {
  @apply h-4.5 bg-neutral text-center rounded-md w-fit flex justify-center items-center text-xs font-medium text-gray-700;
  padding: 2px 10px;
}
.standard-table-cell-date {
  @apply text-xs font-normal text-gray-900;
}
.standard-table-cell {
  @apply text-sm font-normal text-gray-900 capitalize;
}

.standard-text-field > .MuiInputBase-root {
    @apply rounded-lg;
    box-sizing: border-box;
    padding: 0;
}

.standard-text-field > .MuiInputBase-root > .MuiInputBase-input {
  padding: 10px 14px;
}

.standard-btn {
  @apply h-10 w-std capitalize;
  width: 79px;
  padding: 10px 16px;
  border: 1px solid #D0D5DD;
}

.standard-btn:disabled {
  @apply bg-disabled;
}

.standard-btn-action {
  @apply h-10 w-std bg-primary capitalize hover:bg-primary;
  width: 79px;
  padding: 10px 16px;
  border: 1px solid #43D9BB;
}

.standard-search-btn {
  @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 capitalize p-5 text-gray-800 font-medium;
  background: #FFFFFF;
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
}

.modal-btn-cancel {
  @apply capitalize;
  border: 1px solid #D0D5DD;
}

.modal-btn-confirm {
  @apply capitalize bg-error-2 hover:bg-error-2;
  border: 1px solid #D0D5DD;
}

.mui-date-picker {
  width: 100%;
  position: relative;
  .MuiOutlinedInput-root {
      padding-right: 0px;
  }
  .MuiInputAdornment-root {
      position: absolute;
      right: 0;
      padding-right: 16px;
  }
}

.standard-select-options {
  height: 44px;
  border-radius: 8px;
}

.date-range-group {
  display: flex;
  gap: 6px;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
  }
}

.date-range-group > .date-range {
  gap: 6px;
  display: flex;
  justify-content: space-between;
}

.percentage-bounded-profit {
  @apply h-4.5 bg-success text-center rounded-md w-16 flex justify-center items-center gap-1.5 text-xs font-medium text-success-700;
  padding: 2px 10px;
}

.percentage-bounded-profit-base {
  @apply h-6 bg-success text-center rounded-md w-fit flex justify-center items-center gap-1.5;
  padding: 2px 10px;
}

.percentage-bounded-loss {
  @apply h-4.5 bg-error text-center rounded-md w-16 flex justify-center items-center text-xs font-medium text-error-700;
  padding: 2px 10px;
}

.percentage-bounded-loss-base {
  @apply h-6 bg-error text-center rounded-md w-fit flex justify-center items-center gap-1.5;
  padding: 2px 10px;
}

.percentage-bounded-neutral {
  @apply h-4.5 bg-neutral text-center rounded-md w-16 flex justify-center items-center gap-1.5 text-xs font-medium text-gray-700;
  padding: 2px 10px;
}

.percentage-bounded-neutral-base {
  @apply h-6 bg-neutral text-center rounded-md w-fit flex justify-center items-center gap-1.5;
  padding: 2px 10px;
}

