.DARK-twitter-svg {
    path {
        fill: #090B11;
    }
}

.DARK-medium-svg  {
    #fillable-medium {
        fill: #090B11;
    }
}

.DARK-youtube-svg  {
    #fillable-youtube {
        fill: #090B11;
    }
}