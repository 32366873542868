.DEFAULT-apply {
    @apply text-gray-900;
}

.DARK-apply {
    @apply text-white
}

.DEFAULT-text-m-xs {
    @apply text-xs font-medium text-gray-900;
}

.DARK-text-m-xs {
    @apply text-xs font-medium text-white;
}

.DEFAULT-text-m-base {
    @apply text-base font-medium text-gray-900;
}

.DARK-text-m-base {
    @apply text-base font-medium text-white;
}

.DEFAULT-text-n-sm {
    @apply text-sm font-normal text-gray-900;
}

.DARK-text-n-sm {
    @apply text-sm font-normal text-white;
}

.DEFAULT-text-m-sm {
    @apply text-sm font-medium text-gray-900;
}

.DARK-text-m-sm {
    @apply text-sm font-medium text-white;
}

.DEFAULT-text-m-sm-goal {
    @apply text-sm font-medium text-gray-900;
}

.DARK-text-m-sm-goal {
    @apply text-sm font-medium text-gray-500;
}

.DEFAULT-text-m-xl {
    @apply text-xl font-medium text-gray-900;
}

.DARK-text-m-xl {
    @apply text-xl font-medium text-white;
}

.DEFAULT-text-sm-xl {
    @apply text-xl font-semibold text-gray-900;
}

.DARK-text-sm-xl {
    @apply text-xl font-semibold text-white;
}

.DEFAULT-text-m-3xl {
    @apply text-3xl font-medium text-gray-900;
}

.DARK-text-m-3xl {
    @apply text-3xl font-medium text-white;
}

.DEFAULT-text-sm-3xl {
    @apply text-3xl font-semibold text-gray-900;
}

.DARK-text-sm-3xl {
    @apply text-3xl font-semibold text-white;
    color: #FFFFFF !important;
}

.DEFAULT-text-m-lg {
    @apply text-lg font-medium text-gray-900;
}

.DARK-text-m-lg {
    @apply text-lg font-medium text-white;
}

.DEFAULT-border-top {
    border-top: 1px solid #EAECF0;
}

.DARK-border-top {
    border-top: 1px solid #28303F !important;
}

.DEFAULT-border-bottom {
    border-bottom: 1px solid #EAECF0;
}

.DARK-border-bottom {
    border-bottom: 1px solid #28303F;
}

.DEFAULT-text {
    @apply text-sm font-medium;
    color: #43D9BB !important;
}

.DARK-text {
    @apply text-sm font-medium;
    background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.DEFAULT-header-btn-grid-property {
    background-color: #FFFFFF;
}

.DARK-header-btn-grid-property {
    background-color: rgba(0, 0, 0, 0.4);
}

.DEFAULT-scrolled-expanded {
    background: white;
    box-shadow: 0px 15px 25px -15px rgb(100 100 100 / 30%);
}

.DEFAULT-scrolled {
    background-color: white;
    box-shadow: 0px 15px 25px -15px rgb(100 100 100 / 30%);
}

.DARK-scrolled-expanded {
    background-color: #090B11;
    box-shadow: 0px 15px 25px -15px rgb(100 100 100 / 30%);
}

.DARK-scrolled {
    background-color: #090B11;
    box-shadow: 0px 15px 25px -15px rgb(100 100 100 / 30%);
}

.DARK-expandable {
    border: 1px solid #28303F;
    background-color: #090B11 !important;
    .preview-profile {
        border-bottom: 1px solid #28303F !important;
    }
    .special {
        border-top: 1px solid #28303F !important;
    }
    .menu-item:hover {
        svg path {
            stroke: #FFF !important;
        }
        @apply text-white #{!important};
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
    }
}

.DEFAULT-btn {
    .btn-padding {
        margin-bottom: 32px;
        .btn {
            height: 44px;
            text-transform: none;
        }

        .on-default-state {
            background: #AAF0E2;
        }
        .on-invalid-state {
            background: #43D9BB;
        }
    }
}

.DARK-btn {
    .btn-padding {
        margin-bottom: 32px;
        .btn {
            height: 44px;
            text-transform: none;
        }
        .on-default-state {
            background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        }
        .on-invalid-state {
            background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important; 
        }
    }
}

.DEFAULT-bg {
    @apply bg-white;
}

.DARK-bg {
    background-image: url('../images/common/bg.svg');
    background-size: cover;
}

.DEFAULT-on-authenticated-bg {
    background-color: #FCFCFD;
}

.DARK-on-authenticated-bg {
    background-image: url('../images//common/bg-logged-in.svg');
    background-size: cover;

    @media only screen and (max-width: 720px) {
        .MuiPaper-root {
            // background: none !important;
            // border: none !important;
        }
        .header-container-wrapper {
            border-bottom: 1px solid #28303F;
        }
        .DARK-injectable-mobile-header {
            background: #090B11 !important;
            border: none !important;
        }

    }

    .DARK-AppBar {
            border: none !important;
    }
}
.DARK-mobile-sidebar {
    @media only screen and (max-width: 720px) {
        .MuiPaper-root {
            border: none !important;
        }
    }
}

.DARK-Mui-Config {
    .override-cancel-btn {
        background: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F !important;
        @apply text-white #{!important};
        span {
            @apply text-white #{!important};
        }

        &:hover {
            background: rgb(129, 0, 0) !important;
        }
    }
    .override-save-btn {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: none !important;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        color: white !important;
        &:hover {
            background: linear-gradient(90deg, #43d9bbc5 0%, #029bedc5 84.67%) !important;
        }
    }
    ::-webkit-scrollbar {
        width: 3px; /* 纵向滚动条*/
        height: 5px; /* 横向滚动条 */
        background-color: #090B11 !important;
    }
    
    /*定义滚动条轨道 内阴影*/
    ::-webkit-scrollbar-track {
        background-color: #090B11 !important;
    }
    
    /*定义滑块 内阴影*/
    ::-webkit-scrollbar-thumb {
        background: #28303F !important;
    }
    .MuiDialog-paper, .MuiPickersPopper-paper, .MuiPopover-paper {

        .MuiDialogContent-root {
            overflow: hidden !important;
        }
        background: #090B11 !important;
        border: 2px solid #28303F !important;
        border-radius: 8px !important;
        .MuiMenuItem-gutters, .MuiCalendarOrClockPicker-root, .MuiTypography-root {
            color: #667085 !important;
        }
        .MuiTypography-overline, button {
            color: #FFF !important;
        }
        .PrivatePickersYear-yearButton.Mui-selected, .MuiDayPicker-weekContainer .Mui-selected {
            background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%);
        }
        .MuiPickersDay-root:hover {
            border: 1px solid #FFF !important;
        }
        .MuiMenuItem-gutters:hover {
            background: #28303F;
        }
        .Mui-selected.MuiMenuItem-root {
            color: #FFF !important;
            background: #28303F !important;
        }
    }
    .MuiInputBase-input {
        &:-webkit-autofill {
            transition-delay: 9999s
        }
    }
}
.DEFAULT-input-base {
    .MuiInputBase-input {
        box-sizing: border-box;
        height: 44px;
    }
    .MuiInputBase-root {
        box-sizing: border-box;
        border-radius: 8px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
}

.DEFAULT-divider {
    margin-bottom: 30px;
    width: 360px;
    height: 1px;
    border-bottom: 1px solid #43D9BB;
}

.DARK-divider {
    margin-bottom: 30px;
    width: 360px;
    height: 1px;
    background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%);
}

.DEFAULT-social-login {
    .social-login-divider {
        @apply text-gray-500 text-sm mb-[24px];
        display: flex;
        flex-direction: row;
    }
      
    .social-login-divider:before,
    .social-login-divider:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #76D1BF;
    }
      
    .social-login-divider:before {
        margin: auto 1rem auto auto;
    }
      
    .social-login-divider:after {
        margin: auto auto auto 1rem;
    }
}

.DARK-social-login {
    .social-login-divider {
        @apply text-gray-500 text-sm mb-[24px];
        display: flex;
        flex-direction: row;
    }
      
    .social-login-divider:before,
    .social-login-divider:after {
        content: "";
        flex: 1 1;
        height: 1px;
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%);
    }
      
    .social-login-divider:before {
        margin: auto 1rem auto auto;
    }
      
    .social-login-divider:after {
        margin: auto auto auto 1rem;
    }
}


.DARK-input-base {
    .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }
    .MuiOutlinedInput-root {
        border-radius: 8px !important;
        border: 1px solid #28303F;
        background: rgba(0, 0, 0, 0.2);

    }
    
    .MuiOutlinedInput-root:focus-within {
        background: linear-gradient(#090B11, #090B11) padding-box,
        linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box !important;
        border-radius: 10px;
        border: 1px solid transparent !important; 
    }
    .MuiInputBase-input {
        padding: 8.5px 14px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        // border: 1px solid transparent !important;
        color: #FFFFFF;

        &:-webkit-autofill {
            transition-delay: 9999s
        }
    }
    .MuiInputBase-root {
        background: none !important;
    }
    .MuiFilledInput-root {
        background-color: #43D9BB !important;
    }
}

.DARK-forgot-password, .DARK-register-page {
    .divider {
        height: 1px !important;
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%);
        border-bottom: none !important;
    }
    .mounted {
        @apply text-white;
    }
    .kyc-text {
        color: #43D9BB;
    }
    .mounted-social {
        border: 1px solid #28303F !important;
        &:hover {
            border: 1px solid #43D9BB !important;
        }
    }
    .text-input-title {
        @apply text-gray-500;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }
    .MuiOutlinedInput-root {
        border-radius: 8px;
        border: 1px solid #28303F;
        background: rgba(0, 0, 0, 0.2);
    }
    .MuiOutlinedInput-root:focus-within {
        background: linear-gradient(#090B11, #090B11) padding-box,
        linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box !important;
        border-radius: 10px;
        border: 1px solid transparent !important; 
    }
    .MuiInputBase-input {
        padding: 8.5px 14px;
        background: none;
        border-radius: 8px;
        // border: 1px solid transparent !important;
        color: #FFFFFF;
        &:-webkit-autofill {
            transition-delay: 9999s
        }
    }
    .MuiInputBase-root {
        background: none !important;
    }
    .btn {
        height: 44px;
        text-transform: none;
    }
    .on-default-state {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
    }
    .on-invalid-state {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important; 
    }
}

.DARK-reset-password {
    background-image: url('../../assets/images/common/bg-logged-in.svg') !important;
}

.DEFAULT-container-bg {
    background: #ffffff;
}

.DARK-container-bg {
    background: rgba(9, 11, 17, 0.5);
    border: 1px solid #28303F !important;
}

.DEFAULT-btn-global-style {
    background: #43D9BB;
}

.DARK-btn-global-style {
    background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
    border: none !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: white !important;
}

.DEFAULT-cancel-btn-global-style {
    
}

.DARK-cancel-btn-global-style {
    border: 1px solid #28303F !important;
    color: #FFFFFF !important;
    &:hover {
        background: rgba(82, 82, 82, 0.61) !important;
    }
}

.DEFAULT-footer {
    border: 1px solid #EAECF0;
    background: #FFFFFF;

    .focused-text {
        @apply text-sm font-medium text-gray-700 mx-1;
    }
    .focused-text-2 {
        @apply text-sm font-medium text-gray-700 mx-3;
    }
    .focused-text-3 {
        @apply text-sm font-medium text-gray-700;
    }
}

.DARK-footer {
    border: 1px solid #090B11;
    background: #090B11;

    .focused-text-mobile {
        @apply text-white;
    }
    .docs {
        color: #B9B8BB !important;
    }
    .wrapped-links {
        background: #090B11 !important;
        border: none !important;
    }
    .focused-text {
        @apply text-sm font-medium text-white mx-1;
    }
    .focused-text-2 {
        @apply text-sm font-medium text-white mx-3;
    }
    .focused-text-3 {
        @apply text-sm font-medium text-white;
    }
    .documentation-links {
        @apply text-sm font-medium;
        color: #B9B8BB;
    }
}

.DARK-drag-and-drop {
    .root-box {
        background: transparent;
    }
    .on-default {
        border: 1px dashed #28303F;
        border-style: dashed;
        border-width: 2px;
        border-spacing: 2px;
    }
    .securo-color, .override {
        color: #43D9BB;
    }
    .progress {
        .text {
            @apply text-gray-500;
        }
    }
}

.DEFAULT-developer-tools, .DEFAULT-settings {
    .menu-container-item {
        color: #667085 !important;

        &.selected {
            background: #edfffb;
            border-radius: 10px;
            color: #43d9bb !important;
    
            // svg path {
            //     @apply stroke-white;
            // }
        }
    
        svg path {
            stroke: #667085;
        }
    }
}

.DARK-developer-tools, .DARK-settings {
    .menu-container-item {
        color: #667085;
        &:hover {
            background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%);
            border-radius: 6px;
            transition: opacity 0.5s linear;
            @apply text-white;
    
            svg path {
                @apply stroke-white;
            }
            animation: translate-left-out 0.3s 1 cubic-bezier(1, 1, 1, 1);
            @keyframes translate-left-out {
                0%   { transform:translateX(0%); }
                100% { transform:translateX(5px); }
            }
            transform:translateX(5px);
        }
        &.selected {
            background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%);;
            @apply text-white;
    
            svg path {
                @apply stroke-white;
            }
        }
    
        svg path {
            stroke: #667085;
        }
    }
}

.DARK-settings-tabs {
    .MuiTabs-root {
        border-bottom: 1px solid #28303F;
    }
    .tab-config {
        @apply text-gray-500;
        &.Mui-selected {
            color: #43D9BB;
        }
    }
    .MuiTabs-indicator {
        background-color: #43D9BB;
    }
    .card-title {
        @apply text-white #{!important};
    }
    .divider {
        margin-top: 20px;
        margin-bottom: 24px;
        background: #28303F;
    }
    .MuiPaper-root {
        background-color: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F !important;
        .pill-table-row {
            background-color: transparent !important;
            border-bottom: 1px solid #28303F !important;
        }
    }
    .standard-table-cell, .standard-table-cell-date, .permission-label, .ip-input {
        @apply text-white;
    }

    .permission-label {
        @apply text-sm font-medium;
    }

    .highlights, .status, .loading-keys {
        @apply text-gray-500 #{!important};
    }

    .add-ip-address-btn {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: none;
    }

    .standard-btn-action, .button-action {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: none;
        border-radius: 8px;
        padding: 10px 16px !important;
        height: 40px;
    }

    .button-action:disabled {
        background: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F !important;
        @apply text-gray-500;
    }

    // GENERATED KEY LABEL
    .text-label, .status-value {
        @apply text-white;
    }
    .pair-wise {
        margin-top: 10px;
    }
    .standard-text-field {
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
        }
        .MuiInputBase-input {
            background: rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            border: 1px solid #28303F !important;
            @apply text-white;
        }
        .MuiInputBase-input::placeholder {
            @apply text-gray-500;
        }
        .MuiInputBase-input:hover {
            border: 1px solid white !important;
        }
        .MuiInputBase-input:focus {
            background: linear-gradient(#090B11, #090B11) padding-box,
            linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
            border: 1px solid transparent !important;       
        }
    }
    .api-field {
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
        }
        .MuiOutlinedInput-input {
            padding: 0px 10px;
            height: 40px;
        }
        .MuiInputBase-input {
            background: rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            border: 1px solid #28303F !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            @apply text-white;
        }
        .MuiInputBase-input::placeholder {
            @apply text-gray-500;
        }
        .MuiInputBase-input:hover {
            border: 1px solid white !important;
        }
        .MuiInputBase-input:focus {
            background: linear-gradient(#090B11, #090B11) padding-box,
            linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
            border: 1px solid transparent !important;       
        }
    }

    .DARK-select-settings {
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
        }
        .MuiOutlinedInput-root {
            border-radius: 8px;
            border: 1px solid #28303F !important;
            background: rgba(0, 0, 0, 0.2);
        }
        .MuiOutlinedInput-root:focus-within {
            background: linear-gradient(#090B11, #090B11) padding-box,
            linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
            border-radius: 10px;
            border: 1px solid transparent !important; 
        }
        .MuiInputBase-input {
            padding: 8.5px 14px !important;
            background: none !important;
            border-radius: 10px;
            // border: 1px solid transparent !important;
            color: #FFFFFF;
        }
        .MuiSvgIcon-root {
            fill: #FFF !important;
        }
    }
    .is-copy {
        margin-top: 1px;
        height: 42px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .card {
        background-color: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F !important;

        h3 {
            @apply text-white;
        }
        .notice {
            @apply text-gray-500;
        }
        .button-action {
            position: relative;
            background-image: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
            z-index: 1;
            overflow: hidden;
        }
        .button-action::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(90deg, #43D9BB 50%, #0299ED 100.00%) !important;
            z-index: -1;
            transition: opacity 0.5s linear;
            opacity: 0;
        }
        .button-action:hover::before {
            opacity: 1;        
        }
    }

    .btn-outline, .cancel-btn {
        height: 40px;
        background: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F !important;
        @apply text-white #{!important};
        span {
            @apply text-white #{!important};
        }

        &:hover {
            background: rgb(129, 0, 0) !important;
        }
    }

    .adjustable-border {
        border: 1px solid #28303F !important;
        border-left: none !important;
        border-right: none !important;
    }

    .adjustable-border-bottom {
        border-bottom: 1px solid #28303F !important;
    }
    .endpoints-table {
        .header-pill-table-rows, .webhooks-pill-table-row {
            background: transparent !important;
            border-bottom: none !important;
        }
        .url, .mode {
            @apply text-white;
        }
    }
}

.DARK-referral {
    .hightlights {
        @apply text-white #{!important};
    }
    .text-label {
        @apply text-gray-500 #{!important};
    }
    .input-field {
        .MuiOutlinedInput-input:disabled {
            background: transparent !important;
            border: transparent !important;
            color: #FFFFFF;
        }
        .MuiOutlinedInput-root {
            background: rgba(0, 0, 0, 0.2);
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border: 1px solid #28303F !important;
            color: #FFFFFF;
            &:hover {
                border: 0.5px solid #FFF !important;
            }
        }
    }
    .is-copy {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .button-action {
        position: relative;
        background-image: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        z-index: 1;
        overflow: hidden;
    }
    .button-action::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(90deg, #43D9BB 50%, #0299ED 100.00%) !important;
        z-index: -1;
        transition: opacity 0.5s linear;
        opacity: 0;
    }
    .button-action:hover::before {
        opacity: 1;        
    }
}

.DARK-modal, .DARK-modal-paper {
    position: relative;
    border: 1px solid transparent;
    border-radius: 12px !important;
    background: linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%);
    /* just to show box-shadow still works fine */
    // box-shadow: 0 3px 9px black, inset 0 0 9px white;
    &::after{
        position: absolute;
        background: #090B11;
        content: '';
        z-index: -1;
        border-radius: 11px;
        top: 50%;
        left: 50%;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        transform: translate(-50%, -50%);
    }
    .file-name, .highlights {
        @apply text-white #{!important};
    }
    .highlights-2 { @apply text-gray-500 #{!important}; }
    .focused { color: #d92d20 !important; }
    .btn-rm {
        background: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F !important;
        @apply text-white #{!important};
        span {
            @apply text-white #{!important};
        }

        &:hover {
            background: rgb(129, 0, 0) !important;
        }
    }
    .btn-confirm {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: none;
    }
    .modal-btn-confirm {
        border: none !important;
    }
}

.DARK-modal-paper {
    .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }
    .MuiOutlinedInput-root {
        border-radius: 8px;
        border: 1px solid #28303F;
        background: rgba(0, 0, 0, 0.2);
    }
    .MuiOutlinedInput-root:focus-within {
        background: linear-gradient(#090B11, #090B11) padding-box,
        linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
        border-radius: 10px;
        border: 1px solid transparent !important; 
    }
    .MuiInputBase-input {
        padding: 8.5px 14px;
        background: none;
        border-radius: 10px;
        // border: 1px solid transparent !important;
        color: #FFFFFF;
    }
}

.DARK-switch {
    .MuiSwitch-track {
        border: 2px solid #0299ED !important;
        background-color: transparent !important;

    }
    .MuiSwitch-switchBase {
        margin: 2.5px 0px 0px 2.5px !important;
    }
    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
        border: none !important;
        background-color: #43D9BB !important;
    }
}

.DARK-alertbox-modal {
    .MuiPaper-root {
        position: relative;
        border: 1px solid transparent !important;
        background: linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) !important;
        border-radius: 12px !important;
        &::after{
            position: absolute;
            background: #090B11;
            content: '';
            z-index: -1;
            border-radius: 11px;
            top: 50%;
            left: 50%;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            transform: translate(-50%, -50%);
        }

        .highlights {
            @apply text-white;
        }
        .btn-cancel {
            background: rgba(0, 0, 0, 0.2) !important;
            border: 1px solid #28303F !important;
            span {
                @apply text-gray-500 #{!important};
            }
    
            &:hover {
                span { 
                    @apply text-white #{!important}; 
                }
                background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
            }
        }
        .btn-confirm {
            background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
            border: none;
        }
    }
}

// &.selected {
//     background: #EDFFFB;
//     color: #43D9BB;
//     border-radius: 10px;
// }

// // &.selected: hover {
// //     background: linear-gradient(180deg, #76D1BF 0%, rgba(118, 209, 191, 0.73) 100%);
// //     color: #ffffff;
// // }

// .menu-item-icon {
//     margin-right: 12px;
//     width: 20px;

//     &.icon-selected {
//         // filter: invert(99%) sepia(1%) saturate(2%) hue-rotate(157deg) brightness(103%) contrast(100%);
//     }
// }