@use "../../components/SecuroButtons/SecuroButtonWithLoader/style.scss";
@import "../../root/variables.scss";

.MuiTabs-flexContainer {
	gap: 24px;
}

.tab-config {
	text-transform: none;
	padding: 0px;
	min-width: auto;
}
.setting-page {
	width: 100%;
	@media only screen and (min-width: 720px) {
		margin-top: 123px;
		margin-left: 32px;
		margin-right: 32px;
	}
	@media only screen and (max-width: 720px) {
		margin-left: 16px;
		margin-right: 16px;
	}
	.MuiTabs-root {
		border-bottom: 1px solid #EAECF0;
	}
}

.individual-setting-page {
	width: 100%;
	@media only screen and (min-width: 720px) {
		margin-top: calc(123px - 32px);
		margin-left: 32px;
		margin-right: 32px;
	}
	@media only screen and (max-width: 720px) {
		margin-left: 16px;
		margin-right: 16px;
	}
	.MuiTabs-root {
		border-bottom: 1px solid #EAECF0;
	}
}