.DARK-transaction-listing {
    .divider {
        margin-top: 20px;
        margin-bottom: 24px;
        background: #28303F !important;
    }

    .empty_card {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid #28303F;

        .adjustable {
            @apply text-white;
        }
    }

    .bounded-data {
        border-right: 1px solid #28303F !important;
    }

    .standard-select-options {
        background: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F !important;
        color: #FFF !important;

        .MuiOutlinedInput-root {
            border-radius: 8px;
            border: 1px solid #28303F;
            background: rgba(0, 0, 0, 0.2);
        }

        .MuiOutlinedInput-root {
            background: linear-gradient(#090B11, #090B11) padding-box,
                linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box !important;
            border-radius: 10px;
            border: 1px solid transparent !important;
        }

        .MuiInputBase-input {
            padding: 8.5px 14px;
            background: none;
            border-radius: 10px;
            // border: 1px solid transparent !important;
            color: #FFFFFF;
        }

        .MuiInputBase-root {
            background: none !important;
        }

        .MuiFilledInput-root {
            background-color: #43D9BB !important;
        }

        .MuiSvgIcon-root {
            fill: #FFF !important;
        }
    }

    .MuiTableContainer-root {
        background: rgba(9, 11, 17, 0.5);
        border: 1px solid #28303F;

        .standard-table-cell-date {
            @apply text-xs font-normal text-gray-500;
        }

        .standard-table-cell {
            @apply text-sm font-normal text-white capitalize;
        }
    }

    .of-table-head {
        .custom-cell {
            padding: 10px 12px;
            border-bottom: 1px solid #28303F;
        }
    }

    .of-table-body {
        .MuiTableCell-body {
            padding: 10px 12px;
            font-size: 12px;
            border-bottom: 1px solid #28303F;
            min-width: 64px;
        }

        .on-standard {
            font-weight: 400;
        }

        .on-status {
            font-weight: 500;
        }

        .wrapped-status {
            width: 72px;
            height: 22px;
            padding: 2px 8px;
            text-align: center;
            border-radius: 16px;
        }

        .case-success {
            background: #ECFDF3;
        }

        .case-pending {
            background: #F2F4F7;
            ;
        }
    }

    .of-table-body:hover {
        .standard-table-cell-date {
            @apply text-white;
        }

        background-color: #28303F;
    }

    .show-more {
        border-bottom: 1px solid transparent;
    }
}

.DARK-basic-details {
    .divider {
        background: #28303F !important;
    }

    .transaction,
    .first-row,
    .multi-amount,
    .second-row,
    .third-row,
    .wallet-address,
    .sub-headings {
        @apply text-white #{!important};
    }

    .timeline,
    .payment,
    .headings,
    .api-log {
        @apply text-gray-500;
    }

    .tx-hash-heading {
        @apply text-gray-500 font-normal;
    }

    .MuiTableContainer-root {
        background: rgba(9, 11, 17, 0.5);
        border: 1px solid #28303F;

        .standard-table-cell-date {
            @apply text-xs font-normal text-gray-500;
        }

        .standard-table-cell {
            @apply text-sm font-normal text-white capitalize;
        }
    }

    .of-table-head {
        .custom-cell {
            border-bottom: 1px solid #28303F;
        }
    }

    .of-table-body {
        .MuiTableCell-body {
            font-size: 12px;
            border-bottom: 1px solid transparent;
            min-width: 64px;
            color: white;
        }

        .on-standard {
            font-weight: 400;
        }

        .on-status {
            font-weight: 500;
        }

        .case-success {
            background: #ECFDF3;
        }

        .case-pending {
            background: #F2F4F7;
            ;
        }
    }

    .of-table-body:hover {
        .standard-table-cell-date {
            @apply text-white;
        }

        background-color: #28303F;
    }

    .empty_card {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid #28303F;

        .adjustable {
            @apply text-white;
        }
    }
}