.modal-paper {
    max-width: 500px;

    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24;
    
    border-radius: 15px !important;

    @media only screen and (max-width: 720px) {
        width: calc(100% - 32px);
    }

    .modal-content {
        padding: 24px;
    }


    .cancel-setting {
        position: absolute;
        cursor: pointer;
        right: -16px;
        z-index: 1;
        top: -16px;
        width: 32px;
        height: 32px;
        border-radius: 99px;
        background: #C4C4C4;
        background-image: url("../../assets/images/icons/x.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}
