// @keyframes animation-name {
//     0% {
//       transform: translateX(0%)
//     }
//     100% {
//       transform: translateX(-30%)
//     }
// }

.collection {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    .mid-text {
        justify-content: flex-start;
        font-weight: 800;
        font-size: 1.6vh;
        color: #FFFFFF;
    }

    .long-text-normal {
        justify-content: flex-start;
    }

    .long-text-animate {

        justify-content: flex-start;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 800;
        font-size: 1.6vh;
        color: #FFFFFF;
    }
}

.sidebar-container {
    // padding-left: 24px;
    // padding-right: 24px;
    z-index: 888;
    min-width: 255px;
    width: 320px;
    // width: 350px;
    // border-right: 4px solid;
    // border-image: url("../../assets/images/common/sidebar.svg") 11;
    // border-image-width: 2;
    height: 100%;
    // overflow-y: scroll;
    font-family: 'Inter';
    // border-right: 1px solid #EAECF0;

    .menu-sidebar {
        height: 70%;
        overflow-y: auto;
        margin-bottom: 10px;
    }
    .bottom-sidebar-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        .flex-bottom {

            width: calc(100% - 40px);
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .switcher {
                display: flex;
                align-items: center;
                gap: 10px;
                padding-bottom: 20px;
                
            }

            .user-wrapper {
                padding-top: 20px;
                padding-bottom: 20px;
                width: 100%;
                display: flex;
                gap: 8px;
                .icon {
                    width: 20%;
                    img {
                        cursor: pointer;
                        width: 40px;
                        max-width: 40px;
                        height: 40px;
                        border-radius: 200px;
                    }
                }
                .about {
                    max-width: 141.33px;
                    width: 65%;
                    overflow: hidden;
    
                    .user-name {
                        word-wrap: break-word;
                    }
    
                    .user-mail {
                        word-wrap: break-word;
                        // &:hover {
                        //     transform: translate3d(-20%, 0%, 0);
                        //     transition: transform 2.5s cubic-bezier(0.76, 0, 0.24, 1);
                        // }
                    }
                }
                @-webkit-keyframes rotate {
                    from {
                      -webkit-transform: rotate(360deg);
                    }
                    to { 
                      -webkit-transform: rotate(0deg);
                    }
                  }
                  
                .logout {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    width: 15%;
                }
            }
        }

    }
    .sidebar-info {
        // margin: 30px 0px;

        .header {
            // position: absolute;
            // margin-top: -30px;
            // // margin-left: 10px;
            // margin-top: -30px;
            color: white;
            // background-color: red;
            padding: 0px 6px;
            text-align: center;
        }

        .info-item-wrapper {
            padding: 16px;

            .title {
                font-size: 16px;
            }

            .info-item-container {
                background-color: #630AFF;
                color: #ffffff;
                padding: 8px;

                p {
                    margin: 0
                }

                .content-text-upper {
                    font-size: 24px;
                    display: block;
                }

                .content-text-lower {
                    font-size: 18px;
                }
            }
        }
    }

    .builtBy-container {
        margin-bottom: 10px;

        .item-desc {
            color: #76D1BF;
            font-weight: 200;
            font-size: 16px;
        }
    }

    .FollowUs-container {

        .item-desc {
            color: #76D1BF;
            font-weight: 200;
            font-size: 16px;
        }

        .img-container {
            margin-bottom: 10px;

            .img-styles {
                width: 30px;
                margin-left: 4px;
                margin-right: 4px;
            }
        }
    }
    .company-logo-container {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 32px;
        margin-bottom: 32px;
        svg {
            max-width: 146px;
        }
    }
    .upper-container {

        .inner-menu {
            height: 0px;
            transition: all 0.2s ease-in-out;
        }
        .stated {
            overflow: hidden;
            height: 0px;
            transition: all 0.5s ease-out;
            &.expandable {
                height: auto !important;
            }
        }
        .menu-container-normal {
            a {
                text-decoration: none;
                color: inherit;
            }
            width: 95%;

            .menu-container-item {
                overflow: hidden;
                white-space: nowrap;
                cursor: pointer;
                margin-top: 5px;
                margin-bottom: 5px;
                margin-left: 4px;
                margin-right: 4px;
                padding: 8px 16px;

                &.selected {
                    border-radius: 6px;
                }

                .menu-item-icon {
                    margin-right: 12px;
                    width: 20px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .sidebar-container {
        width: 260px;
    }
}

@media (max-width: 305px) {
    .sidebar-container {
        width: 170px;
    }
}