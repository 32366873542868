.kyc-container {
  width: 50%;
  max-width: 80%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .kyc-mini-container-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .kyc-mini-container {
      width: 70%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .page-buttons {
        margin-top: 8px;

        .back-next {
          border-radius: 8px;
        }
      }

      .kyc-heading {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .kyc-heading-text {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 44px;
          /* identical to box height */

          text-align: center;
        }
      }

      .kyc-subheading {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .kyc-subheading-text {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          margin-bottom: 20px;

          color: #000000;
        }
      }

      .documents {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .passport,
        .national-id {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          margin-bottom: 5px;
          color: #000000;
        }

        .styled-button {
          background: #ffffff;
          border: 1px solid #000000;
          border-radius: 10px;
          padding: 10px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .text {
            margin-left: 15px;
            text-transform: none;
            color: rgba(0, 0, 0, 0.5);
          }

          &.error {
            border-color: red;
          }
        }
      }
    }
  }

  .kyc-mini-container-last {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .kyc-heading {
      margin: 8px;

      .kyc-heading-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        text-align: center;

        color: #000000;
      }
    }

    .kyc-subheading {
      margin: 8px;

      .kyc-subheading-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        text-align: center;

        color: #000000;
      }
    }

    .button-container {
      margin-top: 18px;
      width: 55%;

      .button-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 33px;
        /* identical to box height */
        border-radius: 10px;

        text-align: center;

        color: #ffffff;
      }
    }
  }
}

#button-container {
  margin-top: 18px;
  width: 55%;
  margin-left: 23%;

  #button-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    /* identical to box height */
    border-radius: 10px;

    text-align: center;

    color: #ffffff;
  }
}

#completed-kyc {
  width: 100%;
}

@media (max-width: 800px) {
  .kyc-container {
    width: 100%;
    max-width: 100%;

    .kyc-mini-container-form {
      .kyc-mini-container {
        width: 90%;
      }
    }
  }
}