.reporting {
    width: 100%;

    @media only screen and (min-width: 720px) {
        padding-left: 32px;
        padding-right: 32px;

        .input-action-wrapper {
            gap: 6px;

            .input-date {
                display: flex;
                gap: 6px;
            }
        }

    }

    .chart-holster {
        position: relative;
        height: 100%;
        width: 100%;
    }

    @media only screen and (max-width: 720px) {
        padding-left: 16px;
        padding-right: 16px;

        .action-panel {
            flex-direction: column;

            .input-action-wrapper {
                flex-direction: column;
                margin-top: 10px;
                width: 100%;
                gap: 6px;

                .input-date {
                    display: flex;
                    gap: 6px;
                    justify-content: space-between;
                }

                .search-action {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }

    .MuiCard-root {
        box-shadow: none;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
    }

    .groupButton {
        @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 capitalize p-5 text-gray-800 font-medium;
        height: 40px !important;
        background: #FFFFFF;

        @media only screen and (max-width: 720px) {
            width: 100%;
        }
    }

    .groupButton:hover {
        @apply bg-gray-100 shadow-none;
    }

    .exportBtn {
        display: flex;
        gap: 9.67px;
        @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 text-gray-800 font-medium;
        width: 105px;
        text-transform: none;
        height: 40px !important;
        background: #FFFFFF;
    }

    .MuiCard-root {
        border-radius: 8px;
        padding: 24px;
    }

    .replace-height {
        height: 267px;
    }

    .dashboard-root-box {
        margin-top: 24px;

        .upper-grid {

            width: 100%;

            @media only screen and (min-width: 720px) {
                height: 267px;
                display: flex;
                gap: 24px;
            }

            @media only screen and (max-width: 720px) {
                display: flex;
                flex-direction: column;
                gap: 24px;
            }

            .chart-panel {
                @media only screen and (min-width: 720px) {
                    width: 60%;
                }

                @media only screen and (max-width: 720px) {
                    width: 100%;
                }

                height: 100%;

                .card-wrap {
                    height: 100%;

                    @media only screen and (max-width: 720px) {
                        .headings-group {
                            justify-content: flex-start;

                            .about {
                                width: 70%;
                            }

                            .time-period {
                                display: flexs;
                                justify-content: center;
                                align-items: center;
                                padding: 2px 10px !important;
                                height: fit-content;
                            }
                        }
                    }

                }
            }

            .upper-side-panel {
                @media only screen and (min-width: 720px) {
                    width: 40%;
                }

                @media only screen and (max-width: 720px) {
                    width: 100%;
                }

                height: inherit;
                display: flex;
                flex-direction: column;
                gap: 24px;

                .section-deposit {
                    height: 50%;

                    .card-data-grid {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 9px;
                        justify-content: space-between;

                        .tda-payment {
                            display: flex;
                            gap: 10px;
                        }
                    }
                }

                .section-withdraw {
                    height: 50%;

                    .card-data-grid {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 9px;
                        justify-content: space-between;

                        .twa-payment {
                            display: flex;
                            gap: 10px;
                        }
                    }
                }
            }
        }
    }
}

.new-customer {
    margin-top: 32px;
}

.heading-standard {
    margin-top: 32px;
    margin-bottom: 20px;
}

.divider-spacer {
    margin-top: 20px;
    margin-bottom: 24px;
}