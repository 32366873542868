.developer-webkook-events-container {

    @media only screen and (min-width: 720px) {
        padding-left: 32px;
        padding-right: 32px;

        .input-date {
            display: flex;
            gap: 8px;
        }

        .search-btn {
            width: 79px;
            background: #FFFFFF;

            @media (max-width: 720px) {
                width: 50%;
            }

            height: 40px;
        }

        .exportBtn {
            display: flex;
            gap: 9.67px;
            @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 text-gray-800 font-medium;
            width: 105px;
            text-transform: none;
            height: 40px !important;
            background: #FFFFFF;
        }
    }

    @media only screen and (max-width: 720px) {
        padding-left: 16px;
        padding-right: 16px;

        .mobile-flex {
            margin-top: 8px;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;

            a {
                width: 100%;
            }
        }

        .exportBtn {
            display: flex;
            gap: 9.67px;
            @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 text-gray-800 font-medium;
            width: 100%;
            text-transform: none;
            height: 40px !important;
            background: #FFFFFF;
        }

        .action-panel {
            flex-direction: column;

            .input-action-wrapper {
                flex-direction: column;
                margin-top: 10px;
                width: 100%;

                .input-date {
                    display: flex;
                    gap: 8px;
                }

                .search-action {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                }

                .search-btn {
                    width: 79px;
                    background: #FFFFFF;

                    @media (max-width: 720px) {
                        width: 100%;
                    }

                    height: 40px;
                }

                .mobile-flex {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                }

                .exportBtn {
                    display: flex;
                    gap: 9.67px;
                    @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 text-gray-800 font-medium;
                    width: 105px;
                    text-transform: none;
                    height: 40px !important;
                    background: #FFFFFF;
                }
            }
        }
    }

    .webhook-events {
        display: flex;
        gap: 10px;
        @apply text-lg font-medium;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
    }

    .groupButton {
        @apply font-normal border-solid border border-gray-300 text-gray-700 h-10 bg-gray-25 rounded-lg capitalize p-5;
        height: 40px;
    }

    .groupButton:hover {
        @apply bg-gray-100 shadow-none;
    }

    .standard-btn {
        @apply text-sm font-medium text-gray-700 h-10 bg-gray-25 rounded-md capitalize p-5;
        /* Base/White */
        padding: 10px 16px;
        background: #FFFFFF;
        /* Gray/300 */

        border: 1px solid #D0D5DD;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
    }

    .padded-options {
        @media only screen and (min-width: 720px) {
            padding-bottom: 150px;
        }
    }

}

.webhook-events-details-container {
    width: 100%;

    @media only screen and (min-width: 720px) {
        margin-top: 132px;
        padding-left: 32px;
        padding-right: 32px;
    }

    @media only screen and (max-width: 720px) {
        padding-left: 16px;
        padding-right: 16px;
    }

    .webhook-events {
        @apply text-lg font-medium;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
    }

    .basic-details {

        .headings {
            @apply font-normal text-base text-gray-500;
        }

        .first-row {
            @apply font-semibold text-4xl;
        }

        .second-row {
            @apply font-medium text-base;
        }

        .third-row {
            @apply font-normal text-base;
        }
    }

    .webhook-log-container {

        .webhook-log {
            @apply font-medium text-lg;
        }
    }

}