.password-form-input {
    .MuiOutlinedInput-root {
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.05);
        height: 44px;
        .MuiInputBase-input {
            background-color: transparent;
        }
    }

    .MuiFormHelperText-root {
        margin: 0;
        padding-top: 6px;
        padding-bottom: 0px;
        height: auto;
        line-height: 1.05rem;
        text-align: justify;
    }
}

.input-standard {
    .hide-adorment {
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding: 16px 14px;
        pointer-events: none;

        img {
            pointer-events: fill;
            cursor: pointer;
        }
    }
    .MuiOutlinedInput-root {
        padding-left: 0px;
        padding-right: 0px;
    }

    .MuiSvgIcon-root {
        height: 18px;
    }
    .MuiButtonBase-root {

    }
    .MuiInputBase-input {
        box-sizing: border-box;
        height: 44px;
    }
    .MuiInputBase-root {
        box-sizing: border-box;
        border-radius: 8px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        background-color: #FFFFFF;
    }
    .MuiFormHelperText-root {
        margin: 0;
        padding-top: 6px;
        padding-bottom: 0px;
        height: auto;
        line-height: 1.05rem;
    }


    .btn-padding {
        margin-bottom: 32px;
        .btn {
            height: 44px;
            text-transform: none;
        }

        .on-default-state {
            background: #AAF0E2;
        }
        .on-invalid-state {
            background: #43D9BB;
        }

    }

    .create-account {
        display: flex;
        justify-content: center;
        span {
            color: #43D9BB;
            cursor: pointer;
        }
    }
}
@media only screen and (min-width: 720px) {
    .password-container {
    
        width: 100%;
    
    
        .form-container {
            border-bottom: 1px solid #EAECF0;
            padding-top: 16px;
            padding-bottom: 32px;
            margin-bottom: 20px;
            .form-flex {
                width: 520px;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    
    }
}

@media only screen and (max-width: 720px) {
    .password-container {
    
        width: 100%;
    
    
        .form-container {
            border-bottom: 1px solid #EAECF0;
            padding-top: 24px;
            padding-bottom: 24px;
            .form-flex {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    
    }
}
