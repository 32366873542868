@media only screen and (min-width: 720px) {
    .register-page-wrapper {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    .upper-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .back-btn {
            cursor: pointer;
            margin-top: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
    
            img {
                width: 11.67px;
                height: 11.67px;
            }
        }
            .on-unchecked {
                display: flex;
                width: 768px;
                height: 52px;
                background: #FFFBFA;
                /* Error/300 */

                border: 1px solid #FDA29B;
                border-radius: 8px;
                justify-content: space-between;
                align-items: center;
                .unchecked-msg {
                    padding: 17.67px;
                    display: flex;
                    align-items: center;
                    gap: 13.67px;

                    img {
                        width: 16.67px;
                        height: 16.67px;
                    }
                }
                .on-close {
                    padding: 17.67px;
                    cursor: pointer;
                }
            }

            .create-acc-logo-wrapper {
                margin-top: 58px;
                margin-bottom: 38px;
            }
            .divider {
                margin-bottom: 30px;
                width: 360px;
                height: 1px;
                border-bottom: 1px solid #43D9BB;
            }
            .intro-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 32px;
            }

            .register-container {
                width: 100%;
                display: flex;
                flex-direction: column;
            
                .btn-decoration {
                    width: 100%;
                    margin-bottom: 16px;
                    .btn-padding {
                        .btn {
                            text-transform: none;
                            width: 100%;
                            height: 44px;
                        }

                        .on-default-state {
                            background: #AAF0E2;
                        }
                        .on-invalid-state {
                            background: #43D9BB;
                        }
                    }
                    
                }

                .wrapped-input {
                    width: 500px;
                    .hide-adorment {
                        position: absolute;
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                        padding: 16px 14px;
                        pointer-events: none;

                        img {
                            pointer-events: fill;
                            cursor: pointer;
                        }
                    }
                    .text-input-title {
                        margin-bottom: 6px;
                    }

                    .MuiInputAdornment-root {
                        cursor: pointer;
                    }
                    .MuiInputBase-input {
                        box-sizing: border-box;
                        height: 44px;
                    }
                    .MuiInputBase-root {
                        box-sizing: border-box;
                        border-radius: 8px;
                        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                        height: 44px;
                        padding: 0px;
                    }
                    .MuiFormHelperText-root {
                        margin: 0;
                        padding-top: 4px;
                        padding-bottom: 0px;
                        height: auto;
                        line-height: 1.05rem;
                    }


                    .create-account {
                        display: flex;
                        justify-content: center;
                        span {
                            color: #43D9BB;
                            cursor: pointer;
                        }
                    }
                    .policy {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                
                        a {
                            color: #43D9BB
                        }
                    }

                }
            
                #welcome-text {
                    font-size: 64px;
                    font-weight: 600;
                    color: #2D433E;
                    white-space: pre;
                    margin-top: 24px;
                    margin-bottom: 24px;
                }
            
                #welcome-desc {
                    margin-top: 0px;
                    font-weight: 400;
                    color: #2E3836;
                }
            
                .on-error {
                    padding-bottom: 20px;
                }
            
                .on-default {
                    padding-bottom: 20px;
                }
            
                .about-wrapped {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                }
                .wrapped-options {
                    padding-bottom: 24px;
                    width: 500px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 5px;
                    .wrapped-remember-me {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                    }
                    .MuiCheckbox-root {
                        padding: 0;
                        color: #D0D5DD;
                    }
                    .Mui-checked {
                        color: #43D9BB;
                    }
                    .agreement {
                        text-align: justify;
                    }
                }

            }
            
            #login-title-container {
                padding: 16px;
        
                .SignIn {
                    font-size: 32px;
                }
            }
        
            #forget-password {
                cursor: pointer;
                color: #43D9BB !important;
                
            }
        }

        .about-wrapped-register {
            height: 10%;
            
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 32px;
        }
    }
}

@media only screen and (max-width: 720px) {
    .register-page-wrapper {
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .upper-wrapper {

            display: flex;
            flex-direction: column;
            align-items: center;
            height: 90%;

            .on-unchecked {
                display: flex;
                width: 768px;
                height: 52px;
                background: #FFFBFA;
                /* Error/300 */
    
                border: 1px solid #FDA29B;
                border-radius: 8px;
                justify-content: space-between;
                align-items: center;
                .unchecked-msg {
                    padding: 17.67px;
                    display: flex;
                    align-items: center;
                    gap: 13.67px;
    
                    img {
                        width: 16.67px;
                        height: 16.67px;
                    }
                }
                .on-close {
                    padding: 17.67px;
                    cursor: pointer;
                }
            }
    
            .create-acc-logo-wrapper {
                margin-top: 48px;
                margin-bottom: 38px;
            }
            .divider {
                margin-bottom: 30px;
                width: calc(100% - 24px);
                height: 1px;
                border-bottom: 1px solid #43D9BB;
            }
            .intro-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 32px;
            }

            .wrapped-input {
                .hide-adorment {
                    position: absolute;
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    padding: 16px 14px;
                    pointer-events: none;

                    img {
                        pointer-events: fill;
                        cursor: pointer;
                    }
                }
                .text-input-title {
                    margin-bottom: 6px;
                }

                .MuiInputAdornment-root {
                    cursor: pointer;
                }
                .MuiInputBase-input {
                    box-sizing: border-box;
                    height: 44px;
                }
                .MuiInputBase-root {
                    box-sizing: border-box;
                    border-radius: 8px;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    height: 44px;
                    padding: 0px;
                }
                .MuiFormHelperText-root {
                    margin: 0;
                    padding-top: 4px;
                    padding-bottom: 0px;
                    height: auto;
                    line-height: 1.05rem;
                }


                .create-account {
                    display: flex;
                    justify-content: center;
                    span {
                        color: #43D9BB;
                        cursor: pointer;
                    }
                }
                .policy {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    text-align: center;
            
                    a {
                        color: #43D9BB
                    }
                }

            }
    
            #register-container {
                width: 100vw;
                display: flex;
                flex-direction: column;
                padding-left: 16px;
                padding-right: 16px;
                padding-bottom: 68px;
            
                #welcome-text {
                    font-size: 64px;
                    font-weight: 600;
                    color: #2D433E;
                    white-space: pre;
                    margin-top: 24px;
                    margin-bottom: 24px;
                }
            
                #welcome-desc {
                    margin-top: 0px;
                    font-weight: 400;
                    color: #2E3836;
                }
            
                .on-error {
                    padding-bottom: 20px;
                }
            
                .on-default {
                    padding-bottom: 20px;
                }
            
                .about-wrapped {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                }
            
                #login-title-container {
                    padding: 16px;
            
                    .SignIn {
                        font-size: 32px;
                    }
                }
            
                #forget-password {
                    cursor: pointer;
                    color: #43D9BB !important;
                    
                }
            }
        }

        .about-wrapped-register {
            height: 10%;
            
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 32px;
        }

        .wrapped-options {
            padding-bottom: 24px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 5px;
            .wrapped-remember-me {
                display: flex;
                align-items: center;
                gap: 4px;
            }
            .MuiCheckbox-root {
                padding: 0;
                color: #D0D5DD;
            }
            .Mui-checked {
                color: #43D9BB;
            }
            .agreement {
                text-align: justify;
            }
        }
        .btn-decoration {
            width: 100%;
            margin-bottom: 16px;
            .btn-padding {
                .btn {
                    text-transform: none;
                    width: 100%;
                    height: 44px;
                }

                .on-default-state {
                    background: #AAF0E2;
                }
                .on-invalid-state {
                    background: #43D9BB;
                }
            }
            
        }

        .policy {
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
    
            a {
                color: #43D9BB
            }
        }
        .back-btn {
            cursor: pointer;
            margin-top: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-bottom: 32px;
    
            img {
                width: 11.67px;
                height: 11.67px;
            }
        }
    }
}

.card-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  font-family: "Inter";
}

.section-title {
	font-family: Inter;
	font-size: 16px;
	font-weight: 700;
    color: #76D1BF;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
