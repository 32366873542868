.developer-reporting-container {
    @media only screen and (max-width: 720px) {
        margin-left: 16px;
        margin-right: 16px;
    }

    @media only screen and (min-width: 720px) {
        margin-left: 32px;
        margin-right: 32px;
    }

    .MuiCard-root {
        box-shadow: none;
    }

    .chart-holster {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .upper-panel {
        margin-bottom: 20px;

        @media only screen and (max-width: 720px) {
            flex-direction: column;

            .date-panel {
                margin-top: 20px;
                margin-bottom: 0px;

                .date-group {
                    display: flex;
                    gap: 6px;

                    .date-picker-input {
                        width: 100%;
                    }
                }
            }
        }

        @media only screen and (min-width: 720px) {

            .date-panel {

                .date-group {
                    display: flex;
                    gap: 6px;

                    .date-picker-input {
                        width: 150px;
                    }
                }
            }
        }
    }

    .api-request-panel {
        margin-top: 20px;

        .card-container {
            padding: 24px;
            background: #FFFFFF;
            border: 1px solid #EAECF0;
            border-radius: 8px;
        }
    }

    .report-overview-panel {
        margin-top: 32px;
    }

    .multigrid-container {
        margin-top: 20px;

        .wrapped-grid {
            display: flex;

            @media only screen and (max-width: 720px) {
                flex-direction: column;

                .MuiGrid-root {
                    max-width: 100%;
                }
            }

            .card-container {
                padding: 24px;
                background: #FFFFFF;
                border: 1px solid #EAECF0;
                border-radius: 8px;
            }
        }

    }

    .dev-reporting {
        @apply text-lg font-medium;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
    }

    .groupButton {
        @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 capitalize p-5 text-gray-800 font-medium;
        background: #FFFFFF;
    }

    .action-btn {
        text-transform: none;
        @apply font-medium text-sm border-solid border border-gray-300 text-gray-700 bg-gray-25 rounded-lg;
        width: 79px;
        height: 40px;
    }

    .groupButton:hover {
        @apply bg-gray-100 shadow-none;
    }

}

.dev-reporting-root {
    width: 100%;
}