.btn-loader {
  background-color: #43D9BB !important;
  text-transform: none;
  padding: 10px 16px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
}

.securo-button-gradient {
  background: linear-gradient(180deg, #43D9BB 0%, rgba(118, 209, 191, 0.73) 100%) !important;
  color: #ffffff !important;
  text-transform: none;
}

.securo-button-lighten {
  background-color: rgba(118, 209, 191, 0.2);
  color: #43D9BB;
}