.sandbox-toggle {
    position: absolute;
    top: 0;
    width: calc(100vw - 260px);
    height: 2px;
    background-color: #43D9BB;
    display: flex;
    justify-content: center;

    .grid-info {
        width: 70px;
        height: 22px;
        font-size: 10px;
        text-align: center;
        padding-top: 4px;
        color: white;
        border-radius: 0px 0px 8px 8px;
        border: 1px solid #43D9BB;
        background-color: #43D9BB;
    }
}

.mobile-btn-grid {
    margin-right: 4px;

    .MuiBadge-dot {
        margin-top: 4px;
        margin-right: 7px;
        min-width: 9px;
        min-height: 9px;
    }
}

.image-sizing {
    @media only screen and (min-width: 720px) {
        height: 40px;
    }
}

@media only screen and (min-width: 720px) {
    .header-container {
        // margin-top: 24px;
        // margin-left: 20px;
        // margin-bottom: 48px;
        // margin-right: 48px;
        z-index: 2;
        position: fixed;
        width: calc(100vw - 260px);
        height: 80px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        min-inline-size: -webkit-fill-available;

        .header-container-wrapper {
            padding-top: 32px;
            padding-bottom: 24px;
            width: calc(100vw - 260px);
            display: flex;
            justify-content: space-between;
            // padding: 24px 3px 24px 38px;

            &.scrolled {
                background-color: white;
                box-shadow: 0px 15px 25px -15px rgb(100 100 100 / 30%);
            }

            &.scrolled-expanded {
                background: white;
                box-shadow: 0px 15px 25px -15px rgb(100 100 100 / 30%);
            }

            .header-container-title {
                margin-left: 32px;

                .header-container-subtitle {
                    margin-top: 10px;
                }
            }

            .header-connect-container-button {
                cursor: pointer;
                margin-right: 8px;
            }

            .header-right-nav {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-right: 32px;
                gap: 5px;

                .btn-grid {
                    width: 40px;
                    height: 40px;
                    border-radius: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    img {
                        width: 20px;
                        height: 20px;
                    }

                    .MuiBadge-dot {
                        margin-top: 4px;
                        margin-right: 7px;
                        min-width: 9px;
                        min-height: 9px;
                    }
                }

                .btn-profile-grid {
                    cursor: pointer;
                }
            }
        }

        .header-menu-container {

            .header-menu-item {
                margin: 0px 48px;
                cursor: pointer;
            }
        }
    }

}

@media only screen and (max-width: 720px) {

    .header-container {
        // margin-top: 24px;
        // margin-left: 20px;
        // margin-bottom: 48px;
        // margin-right: 48px;
        z-index: 2;
        /* width: calc(100vw - 282px); */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        min-inline-size: -webkit-fill-available;

        .header-container-wrapper {
            padding-top: 96px;
            padding-bottom: 24px;
            border-bottom: 1px solid #EAECF0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            // padding: 24px 3px 24px 38px;

            &.scrolled {
                background: white;
                box-shadow: none;
            }

            &.scrolled-expanded {
                background: white;
                box-shadow: none;
            }

            .header-container-title {
                margin-left: 16px;

                .header-container-subtitle {
                    margin-top: 4px;
                }
            }

            .header-connect-container-button {
                cursor: pointer;
                margin-right: 8px;
            }

            .header-right-nav {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-right: 16px;
                gap: 5px;

                .btn-grid {
                    width: 40px;
                    height: 40px;
                    background-color: #FFFFFF;
                    border-radius: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

                .btn-profile-grid {
                    cursor: pointer;
                }
            }
        }

        .header-menu-container {

            .header-menu-item {
                margin: 0px 48px;
                cursor: pointer;
            }
        }
    }

}

#go-back {
    @media only screen and (min-width: 720px) {
        margin-left: 32px;
    }

    @media only screen and (max-width: 720px) {
        margin-left: 16px;
    }

    cursor: pointer;
    text-decoration: none;
    margin-bottom: 24px;
}

#account-action-button {
    margin-right: 4px;

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;


        .icon-align {
            align-self: flex-start;
        }
    }
}

#name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    background-color: none;

    min-width: 130px;

    &.expanded {
        background-color: #ffffff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 4px 28px rgb(0 0 0 / 27%);
    }

    .name-container-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .name {
            margin-right: 8px;
            font-weight: 600;
        }
    }

    .expanded-container {
        position: absolute;
        background: white;
        top: 0;
        right: 95px;
        width: 240px;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        box-shadow: 0px 15px 25px -7px rgb(0 0 0 / 27%);

        .preview-profile {
            cursor: default;
            display: flex;
            align-items: center;
            padding: 10px 10px 10px 10px;
            overflow: hidden;

            .preview-items {
                margin-left: 10px;
                display: block;
                overflow: hidden;
            }

            border-bottom: 1px solid #EAECF0;
        }

        .menu-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            width: 100%;
            padding: 12px 16px;

            img {
                width: 18px;
                height: 18px;
            }

            &:hover {

                path {
                    stroke: #43D9BB;
                }

                // &:first-of-type{
                // background-color: #ffffff;
                // color: #000000;
                // border-top-left-radius: 10px;
                // border-top-right-radius: 10px;
                // }
                background-color: #EDFFFB;
                color: #43D9BB;

                // &:first-of-type {
                //     border-top-left-radius: 10px;
                //     border-top-right-radius: 10px;
                // }
                &:last-of-type {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }

            &.special {
                border-top: 1px solid #EAECF0;

                // &:hover {
                //     color: red
                // }
            }

            &:first-of-type {
                border-top: 0px;
            }
        }
    }
}
