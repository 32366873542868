@media only screen and (max-width: 720px) {
    .sizing-option {
        // padding-left: 16px;
        // padding-right: 16px;
    }
}

@media only screen and (min-width: 720px) {
    .sizing-option {
        // padding-left: 32px;
        // padding-right: 32px;
    }
}

.table-container-root {

    border-radius: 8px;

    @media only screen and (max-width: 720px) {
        .MuiTableCell-root {
            padding: 10px 12px;
        }
        .amount {
            min-width: 36px;
            padding: 0;
        }
        .responsive-icon {
            min-width: 36px;
            padding: 0;
        }
    }
}