.view-base-root {

    height: 100vh;
    width: 100%;
    display: flex;
    // background-image: url('../../assets/images/background.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    background-color: #F8F7F7;
    overflow-y: hidden;

    &.not-authorized {
        background-color: #ffffff !important;
    }

    .fixed-content {
        position: relative;
        width: 100vw;

        @media only screen and (max-width: 720px) {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .default-bg {
        background-color: #FFFFFF;
    }

    .on-authenticated-bg {
        background-color: #FCFCFD;
    }

    .cubes-top-left {
        position: absolute;
        top: 0px;
        left: 280px;
        min-width: 64px;
        min-height: 62px;
        // background-image: url('../../assets/images/bgnd-top-left.svg');
    }

    .cubes-top-right {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 168px;
        min-height: 225px;
        // background-image: url('../../assets/images/bgnd-top-right.svg');
    }

    .securo-footer {
        position: absolute;
        bottom: 0;
        padding: 1rem;
        background-color: rgb(235, 195, 64);
        width: 100%;
    }

    // Fill rest of the page, parent must apply display flex, flex-direction of column
    .mobile-footer {
        flex-grow: 1;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .wrapped-links {
            background: #FFFFFF;
            border: 1px solid #EAECF0;
            padding-top: 16px;
            padding-bottom: 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;

            .follow-us {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .img-flex {
                    margin-top: 8px;
                    display: flex;
                    gap: 42.4px;
                }
            }
        }

    }

    .foot-absolute {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: flex-end;
        height: 100%;
        z-index: 1;
        pointer-events: none;
        left: 255px;
        width: calc(100vw - 255px);
        white-space: nowrap;

        .app-footer {
            pointer-events: all;
            width: calc(100vw - 255px);
            overflow-x: auto;
            height: 50px;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 15px;
            padding-right: 25px;

            .accelerated-by {
                display: flex;
                gap: 15px;

                .accelerated-by-images {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 12px;

                }
            }

            .documentation {

                display: flex;
                gap: 4px;

                .documentation-links {
                    display: flex;
                    justify-content: space-between;
                    gap: 2px;
                }
            }

        }
    }
}

.Selection-MuiPaper-root {
    overflow: hidden;

    .MuiPaper-root {
        border: 0;
    }
}