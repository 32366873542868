.selection-root-page {
    position: absolute;
    width: 100vw;
    @media only screen and (min-width: 720px) {
        min-height: 100vh;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    .selection-wrap {
        @media only screen and (max-width: 720px) {
            margin-top: 32px;
        }
        @media only screen and (min-width: 720px) {
            margin-top: 147px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @media only screen and (min-width: 720px) {
            width: 500px;
        }
        @media only screen and (max-width: 720px) {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
        }

        .flex-selections {
            margin-top: 29px;
            margin-bottom: 29px;
            display: flex;
            gap: 25px;

            @media only screen and (max-width: 720px) {
                flex-direction: column;
            }

            .selections {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;

                box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
                border-radius: 8px;

                @media only screen and (min-width: 720px) {
                    width: 442px;
                }
                height: 394px;

                .img-box {
                    padding-top: 24px;
                    padding-bottom: 10px;
                    padding-left: 63px;
                    padding-right: 63px;
                    @media only screen and (max-width: 720px) {
                        padding-top: 42px;
                        padding-left: 32px;
                        padding-right: 32px;
                    }
                }

                .about-box {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    // justify-content: flex-end;
                    gap: 10px;
                    padding-bottom: 24px;
                    padding-left: 24px;
                    padding-right: 24px;
                    text-align: center;
                    overflow: hidden;
                }
            }

            .selections:hover {
                transform: translateY(0.2rem);
                transition: all .5s ease;
            }
        }
    }

    .text-info-area {
        text-align: center;
        width: auto;
    }

    .btn-area-selection {
        margin-top: 29px;
    }


    .about-wrapped-verification {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-top: 32px;
        margin-bottom: 32px;
    }
}

.filler-steps-root {
    width: 360px;
    height: 8px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    .filler-step {
        width: 112px;
        height: 8px;
        border-radius: 4px;
    }
    .case-filled {
        background: #43D9BB;
    }
    .case-unfilled {
        background: #F2F4F7;
    }
}

.modal-layer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (min-width: 720px) {
        width: 503px;
        height: 509px;
    }

    @media only screen and (max-width: 720px) {
        width: calc(100% - 32px);
    }


    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    padding: 24px;
    display: flex;
    flex-direction: column;

    .mdl-img-container {
        margin-top: 20px;
        
        @media only screen and (min-width: 720px) {
            width: 458px;
            height: 337px;
        }
        @media only screen and (max-width: 720px) {
            width: 100%;
            height: 250px;
        }

        display: flex;
        align-items: center;
        justify-content: center;

        overflow: hidden;
        img {
            max-width: fit-content;
            width: auto;
            height: 100%;
        }
    }

    .btn-group {
        margin-top: 32px;
        display: flex;
        width: 100%;
        gap: 12px;
        .btn-rm {
            text-transform: none;
            width: 50%;
            background-color: #FFFFFF;
            border: 1px solid #D0D5DD;
        }
        .btn-confirm {
            text-transform: none;
            width: 50%;
            background-color: #43D9BB;
        }
    }

};