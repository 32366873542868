.DEFAULT-selections {
    background: #FFFFFF;
    border: 1px solid #EAECF0;

    &.selected {
        border: 2px solid #43D9BB !important;
    }
}

.DARK-selections {
    background: transparent !important;
    border: 1px solid #28303F !important;

    &.selected {
        border: 2px solid #43D9BB !important;
    }
}
.DEFAULT-selection-confirm-btn {
    @apply text-base font-medium text-white;
    text-transform: none;
    padding: 10px 18px;
    gap: 8px;

    width: 360px;
    height: 44px;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #43D9BB !important;
}
.DARK-selection-confirm-btn {
    @apply text-base font-medium text-white;
    text-transform: none;
    padding: 10px 18px;
    gap: 8px;

    width: 360px;
    height: 44px;;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important; 
}