.DARK-developer-tools {
    .api-logging {
        @apply text-white;
    }
    .bounded-data {
        border-right: 1px solid #28303F !important;
    }
    .action-wrapper {
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
        }
        .MuiOutlinedInput-root {
            border-radius: 8px;
            border: 1px solid #28303F;
            background: rgba(0, 0, 0, 0.2);
        }
        .MuiOutlinedInput-root:focus-within {
            background: linear-gradient(#090B11, #090B11) padding-box,
            linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
            border-radius: 10px;
            border: 1px solid transparent !important; 
        }
        .MuiInputBase-input {
            padding: 8.5px 14px;
            background: none;
            border-radius: 10px;
            // border: 1px solid transparent !important;
            color: #FFFFFF;
        }
    }
    .search-btn {
        @apply text-white #{!important};
        background: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F !important;
    }
    .search-btn:hover {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: none;
    }
    .export-btn {
        @apply text-white #{!important};
        background: transparent !important;
        border: 1px solid #28303F !important;

        svg path {
            stroke: #FFFFFF !important;
        }
    }
    .export-btn:hover {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: 1px solid #28303F;
    }
    // TABLE
    .MuiTableContainer-root {
        background: rgba(9, 11, 17, 0.5);
        border: 1px solid #28303F;

        .standard-table-cell-date {
            @apply text-xs font-normal text-gray-500;
        }
        .standard-table-cell {
            @apply text-sm font-normal text-white capitalize;
        }
    }
    .of-table-head {
        .custom-cell {
            padding: 10px 12px;
            border-bottom: 1px solid #28303F;
        }
    }
    
    .of-table-body {
        .MuiTableCell-body {
            padding: 10px 12px;
            font-size: 12px;
            border-bottom: 1px solid #28303F;
            min-width: 64px;
        }
        .on-standard {
            font-weight: 400;
            @apply text-white;
        }
        .on-status {
            font-weight: 500;
        }
        .wrapped-status {
            width: 72px;
            height: 22px;
            padding: 2px 8px;
            text-align: center;
            border-radius: 16px;  
        }
        .case-success {
            background: #ECFDF3;
        }
        .case-pending {
            background: #F2F4F7;;
        }
    }

    .of-table-body:hover {
        .standard-table-cell-date {
            @apply text-white;
        }
        background-color: #28303F;
    }
    
    .show-more {
        border-bottom: 1px solid transparent;
    }
    // EOTABLE
    .divider {
        margin-top: 20px;
        margin-bottom: 24px;
        background: #28303F;
    }
    .empty_card {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid #28303F;

        .adjustable {
            @apply text-white;
        }
    }
}

.DARK-api-logging {
    .api-logging,
    .second-row,
    .value {
        @apply text-white #{!important};
    }

    .headings,
    {
        @apply text-gray-500 #{!important};
    }

    .content {
        background: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F;
        border-radius: 8px;

        .react-syntax-highlighter-line-number {
            @apply text-gray-500;
        }
        .token {
            @apply text-red-400 #{!important};
        }
        .boolean {
            @apply text-green-400 #{!important};
        }
        .punctuation {
            @apply text-white #{!important};
        }
        span {
            @apply text-white;
            background: rgba(0, 0, 0, 0.2) !important;
        }
    }
    .divider {
        margin-top: 20px;
        margin-bottom: 24px;
        background: #28303F;
    }
        .empty_card {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid #28303F;

        .adjustable {
            @apply text-white;
        }
        .adjustable-btn {
            position: relative;
            background-image: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
            z-index: 1;
            overflow: hidden;
        }
        .adjustable-btn::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(90deg, #43D9BB 50%, #0299ED 100.00%) !important;
            z-index: -1;
            transition: opacity 0.5s linear;
            opacity: 0;
        }
        .adjustable-btn:hover::before {
            opacity: 1;        
        }
    }
}

.DARK-webhook-events {
    .webhook-events,
    .second-row, 
    .value {
        @apply text-white #{!important};
    }

    .webhook-log
    {
        @apply text-gray-500 #{!important};
    }

    .exportBtn {
        @apply text-white #{!important};
        background: transparent !important;
        border: 1px solid #28303F !important;

        svg path {
            stroke: #FFFFFF !important;
        }
    }
    .exportBtn:hover {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: 1px solid #28303F;
    }
    .webhook-events {
        @apply text-white;
    }
    .empty_card {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid #28303F;
        .adjustable {
            @apply text-white;
        }
    }
    .divider {
        margin-top: 20px;
        margin-bottom: 24px;
        background: #28303F;
    }
    // TABLE
    .MuiTableContainer-root {
        background: rgba(9, 11, 17, 0.5);
        border: 1px solid #28303F;

        .standard-table-cell-date {
            @apply text-xs font-normal text-gray-500;
        }
        .standard-table-cell {
            @apply text-sm font-normal text-white capitalize;
        }
    }
    .of-table-head {
        .custom-cell {
            padding: 10px 12px;
            border-bottom: 1px solid #28303F;
        }
    }
    
    .of-table-body {
        .MuiTableCell-body {
            padding: 10px 12px;
            font-size: 12px;
            border-bottom: 1px solid #28303F;
            min-width: 64px;
        }
        .on-standard {
            font-weight: 400;
            @apply text-white;
        }
        .on-status {
            font-weight: 500;
        }
        .wrapped-status {
            width: 72px;
            height: 22px;
            padding: 2px 8px;
            text-align: center;
            border-radius: 16px;  
        }
        .case-success {
            background: #ECFDF3;
        }
        .case-pending {
            background: #F2F4F7;;
        }
    }

    .of-table-body:hover {
        .standard-table-cell-date {
            @apply text-white;
        }
        background-color: #28303F;
    }
    
    .show-more {
        border-bottom: 1px solid transparent;
    }
    // EOTABLE
    .content {
        background: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F;
        border-radius: 8px;

        .react-syntax-highlighter-line-number {
            @apply text-gray-500;
        }
        .token {
            @apply text-green-400 #{!important};
        }
        .boolean {
            @apply text-red-400 #{!important};
        }
        .punctuation {
            @apply text-white #{!important};
        }
        span {
            @apply text-white;
            background: rgba(0, 0, 0, 0.2) !important;
        }
    }
}