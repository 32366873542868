
.btn-outline {
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.text-label {
  color: #344054;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.6rem;
}

.text-area {
  margin-bottom: 16px;
}

.endpoints-table {
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;

  .MuiPaper-root {
    border-radius: 8px;
  }

  .header-pill-table-rows {
    background-color: #fff;
    padding: 16px 24px;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #EAECF0;
    display: flex;

    @media only screen and (min-width: 720px) {
      .url {
        width: 60%;
      }
      .mode {
        width: 13.33%;
        padding-left: 8px;
      }
      .status {
        width: 13.33%;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 13.33%;
      }
    }

    @media only screen and (max-width: 720px) {
      padding: 8px 12px;
      .url {
        width: 30%;
        max-width: 90px;
      }
      .mode {
        width: 30%;
        padding-left: 8px;
      }
      .status {
        width: 20%;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 20%;
      }
    }
  }

  .webhooks-pill-table-row {
    background-color: #fff;
    padding: 16px 24px;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #EAECF0;
    display: flex;
    @media only screen and (min-width: 720px) {
      
      .url {
        width: 60%;
        overflow: auto;
        white-space: nowrap;
      }
      .mode {
        width: 13.33%;
        padding-left: 8px;
      }
      .status {
        width: 13.33%;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        width: 13.33%;
        
        .action-gap {
          display: flex;

          flex-direction: row;
          gap: 10px;
          width: 100%;
          justify-content: flex-end;
        }
      }
    }

    @media only screen and (max-width: 720px) {
      padding: 8px 12px;
      .url {
        width: 30%;
        max-width: 90px;
        overflow: auto;
        white-space: nowrap;
      }
      .mode {
        width: 30%;
        padding-left: 8px;
      }
      .status {
        width: 20%;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        width: 20%;

        .action-gap {
          display: flex;

          flex-direction: row;
          gap: 10px;
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
  }
}

