.DARK-customer {
    .customer {
        @apply text-white;
    }
    .input-api {
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
        }
        .MuiOutlinedInput-root {
            border-radius: 8px;
            border: 1px solid #28303F;
            background: rgba(0, 0, 0, 0.2);
        }
        .MuiOutlinedInput-root:focus-within {
            background: linear-gradient(#090B11, #090B11) padding-box,
            linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
            border-radius: 10px;
            border: 1px solid transparent !important; 
        }
        .MuiInputBase-input {
            padding: 8.5px 14px;
            background: none;
            border-radius: 10px;
            // border: 1px solid transparent !important;
            color: #FFFFFF;
        }

        // .MuiInputBase-input:hover {
        //     border: 1px solid white !important;
        // }
        // .MuiInputBase-input:focus {
        //     background: linear-gradient(#090B11, #090B11) padding-box,
        //     linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
        //     border-radius: 10px;
        //     border: 1px solid transparent !important;       
        // } 
    }
    .groupButton {
        @apply text-white;
        background: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F;

        svg path {
            stroke: #FFFFFF;
        }
    }
    .groupButton:hover {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: none;
    }
    .create-new-btn {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: none;
    }
    .exportBtn {
        @apply text-white;
        background: transparent !important;
        border: 1px solid #28303F;

        svg path {
            stroke: #FFFFFF;
        }
    }
    .exportBtn:hover {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: 1px solid #28303F;
    }
    .empty_card {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid #28303F;

        .adjustable {
            @apply text-white;
        }
        .adjustable-btn {
            position: relative;
            background-image: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
            z-index: 1;
            overflow: hidden;
        }
        .adjustable-btn::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(90deg, #43D9BB 50%, #0299ED 100.00%) !important;
            z-index: -1;
            transition: opacity 0.5s linear;
            opacity: 0;
        }
        .adjustable-btn:hover::before {
            opacity: 1;        
        }
    }

    // TABLE
    .MuiTableContainer-root {
        background: rgba(9, 11, 17, 0.5);
        border: 1px solid #28303F;

        .standard-table-cell-date {
            @apply text-xs font-normal text-gray-500;
        }
        .standard-table-cell {
            @apply text-sm font-normal text-white capitalize;
        }
    }
    .of-table-head {
        .custom-cell {
            padding: 12px 24px;
            border-bottom: 1px solid #28303F;
        }
    }
    
    .of-table-body {
        .MuiTableCell-body {
            padding: 12px 26px;
            font-size: 12px;
            border-bottom: 1px solid #28303F;
        }
        .on-standard {
            font-weight: 400;
            @apply text-white;
        }
        .on-status {
            font-weight: 500;
        }
        .wrapped-status {
            width: 72px;
            height: 22px;
            padding: 2px 8px;
            text-align: center;
            border-radius: 16px;  
        }
        .case-success {
            background: #ECFDF3;
        }
        .case-pending {
            background: #F2F4F7;;
        }
    }

    .of-table-body:hover {
        .standard-table-cell-date {
            @apply text-white;
        }
        background-color: #28303F;
    }
    
    .show-more {
        border-bottom: 1px solid transparent;
        word-wrap: break-word;
        min-width: 100px;
    }

    .divider {
        margin-top: 20px;
        margin-bottom: 24px;
        background: #28303F;
    }
    // EOTABLE
}

.DARK-customer-details {
    .divider {
        margin-top: 20px;
        margin-bottom: 24px;
        background: #28303F;
    }
    .bounded-data {
        border-right: 1px solid #28303F !important;
    }
    .value, .customer {
        @apply text-white #{!important};
    }
    .form-control-text {
        @apply text-gray-500;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }
    .MuiInputBase-input {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        border: 1px solid #28303F !important;
        @apply text-white;
    }
    .MuiInputBase-input::placeholder {
        @apply text-gray-500;
    }
    .MuiInputBase-input:hover {
        border: 1px solid white !important;
    }
    .MuiInputBase-input:focus {
        background: linear-gradient(#090B11, #090B11) padding-box,
        linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
        border-radius: 10px;
        border: 1px solid transparent !important;       
    }
    .Mui-disabled {
        background: rgba(0, 0, 0, 0.1);
        // @apply text-gray-500;
    }
    .test {
        @apply text-gray-500;
    }
}