.developer-api-logging-container {

    @media (max-width: 720px) {
        padding-left: 16px;
        padding-right: 16px;
    }

    @media (min-width: 720px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    .upper-panel {
        margin-bottom: 20px;
        display: flex;

        @media (min-width: 720px) {
            flex-direction: row;
        }

        @media (max-width: 720px) {
            flex-direction: column;
            // padding-left: 16px;
            // padding-right: 16px;
        }

        .action-wrapper {
            margin-top: 10px;
            display: flex;

            @media (min-width: 720px) {
                flex-direction: row;

                .input-field {
                    height: 40px;

                    .MuiInputBase-root {
                        width: 320px;
                        border-radius: 8px;
                    }
                }
            }

            @media (max-width: 720px) {
                flex-direction: column;

                .input-field {
                    width: 100%;
                    height: 40px;

                    .MuiInputBase-root {
                        border-radius: 8px;
                    }
                }
            }

            .btn-wrapper {

                display: flex;

                @media (min-width: 720px) {
                    margin-left: 4px;
                    flex-direction: row;
                    gap: 6px;
                }

                @media (max-width: 720px) {
                    margin-top: 10px;
                    flex-direction: row;
                    gap: 12px;
                }

                .standard-btn {
                    @apply text-sm font-medium text-gray-700 h-10 bg-gray-25 rounded-md capitalize p-5;
                    /* Base/White */
                    padding: 10px 16px;
                    background: #FFFFFF;
                    /* Gray/300 */

                    border: 1px solid #D0D5DD;
                    /* Shadow/xs */

                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 8px;
                }

                .search-btn {
                    width: 79px;

                    @media (max-width: 720px) {
                        width: 50%;
                    }

                    height: 40px;
                    background: #FFFFFF;
                }

                @media (max-width: 720px) {
                    .csv-btn {
                        width: 50%;
                    }
                }

                .export-btn {
                    display: flex;
                    gap: 9px;
                    min-width: max-content;

                    @media (max-width: 720px) {
                        width: 100%;
                    }

                    height: 40px;
                    background: #FFFFFF;
                }
            }
        }
    }

    .api-logging {
        display: flex;
        gap: 10px;
        @apply text-lg font-medium;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
    }



    .groupButton:hover {
        @apply bg-gray-100 shadow-none;
    }

    .tbl-container {
        margin-top: 20px;
    }

    .padded-options {
        @media only screen and (min-width: 720px) {
            padding-bottom: 150px;
        }
    }

}

.api-logging-details-container {
    width: 100%;
    margin-top: 132px;
    padding-left: 32px;
    padding-right: 32px;
    @media only screen and (max-width: 720px) {
        margin-top: 0px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .api-logging {
        @apply text-lg font-medium;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
    }

    .content-box {

        .basic-details {

            .headings {
                @apply font-normal text-base text-gray-500;
            }

            .first-row {
                @apply font-semibold text-4xl;
            }

            .second-row {
                @apply font-medium text-base;
            }

            .third-row {
                @apply font-normal text-base;
            }
        }

        .request {

            .title {
                @apply font-normal text-base text-gray-500;
            }

            .content {
                @media (max-width: 720px) {
                    overflow-x: scroll;
                }

                @apply font-normal text-base;
            }
        }

        .api-log-container {

            .api-log {
                @apply font-medium text-lg;
            }

            .content {
                @media (max-width: 720px) {
                    overflow-x: scroll;
                }

                @apply font-normal text-base;
            }
        }

        @media only screen and (min-width: 720px) {
            padding-bottom: 150px;
        }

    }

}