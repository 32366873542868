.kyc-container {
    @media only screen and (min-width: 720px) {
        width: 500px;
    }
    @media only screen and (max-width: 720px) {
        width: 100%;
    }
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 20px;
    padding-bottom: 35px;
    border-bottom: 1px solid #EAECF0;

    
.on-default {
    border: 1px dashed #EAECF0;
    border-style: dashed;
    border-width: 2px;
    border-spacing: 2px;
    @media only screen and (min-width: 720px) {
        width: 500px;
    }
    @media only screen and (max-width: 720px) {
        width: 100%;
    }
    height: 126px;
}
.on-uploaded {
    @media only screen and (min-width: 720px) {
        width: 500px;
    }
    @media only screen and (max-width: 720px) {
        width: 100%;
    }
    height: 96px;
    border: 1px solid #43D9BB;
}
}
