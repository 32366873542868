@media only screen and (min-width: 720px) {
    .kyc-drag-and-drop-container {
        display: flex;
        width: 100%;

        .root-box {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;

            border: 1px solid #43D9BB;
            border-radius: 8px;
            padding: 15px;

            .drop-box {
                width: 85%;
                display: flex;
                justify-content: flex-start;
                flex-direction: row;

                .allow-cursor {
                    cursor: pointer;
                }

                .icon {
                    display: flex;
                    width: 10%;

                    .bound-icon {
                        width: 32px;
                        height: 32px;
                        border-radius: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 0px;
                    }

                    .on-uploaded {
                        background: #D1FADF;
                    }
                }

                .text-item {
                    width: 90%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    align-items: flex-start;
                    white-space: nowrap;
                    max-width: 85%;
                    overflow: hidden;

                    .progress {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .bar {
                            margin-top: 2px;
                            width: 85%;
                            height: 8px;
                            background: #43D9BB;
                            border-radius: 10px;
                        }

                        .text {
                            width: 15%;
                        }
                    }
                }

                .icon-wrapper-none {
                    background: #F2F4F7;
                    border: 6px solid #F9FAFB;
                    border-radius: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;

                    img {
                        width: 14px;
                        height: 14px;
                    }
                }

                .text-item-empty {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    .guide-info {
                        display: flex;
                    }
                }
            }

            .action-item {
                width: 15%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                .icons-wrap {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    img {
                        height: 19px;
                        cursor: pointer;
                    }
                }

            }
        }

        .on-default {
            border: 1px dashed #EAECF0;
            border-style: dashed;
            border-width: 2px;
            border-spacing: 2px;
            width: 520px;
            height: 126px;
        }

        .on-uploaded {
            width: 520px;
            height: 76px;
            border: 1px solid #43D9BB;
        }

        .securo-color {
            color: #43D9BB;
        }
    }


    .tabs-content {
        margin-top: 100px;
        margin-left: 32px;
        margin-right: 32px;
        width: 100%;

        .MuiTabPanel-root {
            padding: 0px;
            padding-bottom: 44px;
        }

        .MuiGrid-root {
            margin: 0;
        }

        .MuiTabs-flexContainer {
            gap: 24px;
        }

        .MuiTabs-root {
            border-bottom: 1px solid #EAECF0;
        }

        .tabs-config {
            text-transform: none;
            padding: 0px;
            min-width: auto;
        }


        .simple-select-helper {
            width: 100%;
        }
    }

    #kyc-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 20px;
        padding-bottom: 35px;
        border-bottom: 1px solid #EAECF0;
    }

    #profile-container {
        margin-bottom: 20px;

        .profile-icon-wrapper {
            padding-bottom: 25px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            border-bottom: 1px solid #EAECF0;
            gap: 10px;

            .text-marginalized {
                margin-bottom: 10px;
            }

            .user-profile {
                width: 126px;
                height: 126px;
            }

            .profile-drag-and-drop-container {
                cursor: pointer;
                display: flex;
                height: 126px;
                width: 100%;
                background: #FFFFFF;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding: 16px 24px;
                border-radius: 8px;
                border: 1px dashed #EAECF0;

                .info-wrap {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;

                    .icon-wrap {
                        width: 40px;
                        height: 40px;
                        background-color: #F9FAFB;
                        border-radius: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .text-special {
                        color: #43D9BB;
                    }
                }

            }

            .user-profile-action {
                width: 100%;
                height: 100%;
                padding: 8px;
                display: flex;
                flex-direction: column;
                // margin-left: 8px;
                justify-content: center;

                .name-label {
                    margin: 16px 8px;
                    font-size: 18px;
                    font-weight: 700;
                }

                .upload-button {
                    background: rgba(118, 209, 191, 1);
                    color: #ffffff;
                    border-radius: 10px;
                }

                .remove-button {
                    margin-left: 8px;
                    background-color: none;
                    border-radius: 10px;
                    border: 1px solid rgba(204, 204, 204, 1);
                    color: rgba(204, 204, 204, 1);
                }
            }
        }

        .content-wrapper {
            padding: 16px;
        }
    }

    .root-profile {
        padding-left: 0px;
        padding-right: 0px;

        .about-text {
            padding-bottom: 20px;
        }
    }

    .about-tabs {
        width: 100%;
        border-bottom: 1px solid #EAECF0;
        display: flex;
        flex-direction: column;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .on-updated-container {
        display: flex;
        background: #F6FEF9;
        border: 1px solid #6CE9A6;
        border-radius: 8px;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        .success-msg {
            display: flex;
            gap: 10px;
        }

        .close-icon {
            cursor: pointer;
        }
    }

    .section-one {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid #EAECF0;
        gap: 20px;

        .top {
            display: flex;
            width: 100%;
            gap: 30px;
        }

        .bottom {
            display: flex;
            width: 100%;
            gap: 30px;
        }
    }

    .section-two {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid #EAECF0;

        .street-address {
            margin-top: 20px;
        }

        .street-origin {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .top {
                display: flex;
                width: 100%;
                gap: 30px;
            }

            .bottom {
                display: flex;
                width: 100%;
                gap: 30px;
            }
        }

    }

    #action-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 35px;
        gap: 12px;
        margin-top: 20px;

        .MuiButtonBase-root {
            text-transform: none;
        }

        .cancel-changes-button {
            margin-left: 20px;
            padding: 12px 16px;
            background: #FFFFFF;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 10px;
            width: 65px;
            height: 40px;
        }

        .save-changes-button {
            padding: 12px 16px;
            background: #43D9BB;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 10px;
            width: 80px;
            height: 40px;
        }

        .discard-button {
            padding: 12px 16px;
            background: none;
            border-radius: 10px;
            font-weight: 700;
            color: #76D1BF;
            border: 2px solid #76D1BF;
            min-width: 180px;
        }
    }

    #input-container {
        display: flex;
        flex-direction: column;
        width: 50%;

        .upper-label {
            margin-bottom: 8px;

            &.special {
                color: rgba(158, 158, 158, 1);
            }

            .aesterisk {
                color: red;
            }
        }

        .MuiInputBase-root {
            border-radius: 8px;
        }

        .shorter-input {
            width: 100%;

            .MuiOutlinedInput-root {
                border-radius: 10px;
                box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.05);
            }
        }

        .full-width-textfield {
            .MuiOutlinedInput-root {
                border-radius: 10px;
            }
        }

        .MuiOutlinedInput-root.Mui-disabled {
            .MuiOutlinedInput-input.Mui-disabled {
                background: rgba(204, 204, 204, 0.5);
                border-radius: 10px;
            }
        }

        .disabled {
            color: rgba(204, 204, 204, 0.5);
        }

        .password-form-input {

            .MuiOutlinedInput-root {
                border-radius: 8px;
                box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.05);
                width: 520px;
                height: 44px;

                .MuiInputBase-input {
                    background-color: transparent;
                }
            }

        }

        .date-picker {
            width: 100%;
            position: relative;

            .MuiOutlinedInput-root {
                padding-right: 0px;
            }

            .MuiInputAdornment-root {
                position: absolute;
                right: 0;
                padding-right: 16px;
            }
        }
    }

}

#upload-btn {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    padding: 18.5px 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .text {
        margin-left: 15px;
        text-transform: none;
        color: rgba(0, 0, 0, 0.5);
    }

    width: 85%;
    border-right: 0px;

    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    &.error {
        border-color: red;
    }
}

#upload-btn-small-screen {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    padding: 18.5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 5px;

    .text {
        margin-left: 15px;
        text-transform: none;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        word-break: break-all;
    }

    width: 100%;

    &.error {
        border-color: red;
    }
}

#action-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 18.5px 14px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-left: 0px;
    width: 20%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &.error {
        border-color: red;
    }
}

#download-btn {
    margin-right: 16px;
    margin-left: 16px;
    color: rgba(158, 158, 158, 1);
    cursor: pointer;
    text-align: center;
}

#delete-btn {
    margin-left: 16px;
    margin-right: 16px;
    color: red;
    cursor: pointer;
    text-align: center;
}


@media only screen and (max-width: 720px) {
    .kyc-drag-and-drop-container {
        display: flex;
        width: 100%;

        .root-box {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;

            border: 1px solid #43D9BB;
            border-radius: 8px;
            padding: 15px;

            .drop-box {
                width: 80%;
                display: flex;
                justify-content: flex-start;
                flex-direction: row;

                .allow-cursor {
                    cursor: pointer;
                }

                .icon {
                    display: flex;
                    width: 15%;

                    .bound-icon {
                        width: 32px;
                        height: 32px;
                        border-radius: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 0px;
                    }

                    .on-uploaded {
                        background: #D1FADF;
                    }
                }

                .text-item {
                    white-space: nowrap;
                    width: 80%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    align-items: flex-start;
                    overflow: hidden;

                    .progress {
                        width: 95%;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .bar {
                            margin-top: 2px;
                            width: 85%;
                            height: 8px;
                            background: #43D9BB;
                            border-radius: 10px;
                        }

                        .text {
                            width: 15%;
                        }
                    }
                }

                .icon-wrapper-none {
                    background: #F2F4F7;
                    border: 6px solid #F9FAFB;
                    border-radius: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;

                    img {
                        width: 14px;
                        height: 14px;
                    }
                }

                .text-item-empty {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    .guide-info {
                        display: flex;
                    }
                }
            }

            .action-item {
                width: 20%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                .icons-wrap {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    img {
                        height: 19px;
                        cursor: pointer;
                    }
                }

            }
        }

        .on-default {
            border: 1px dashed #EAECF0;
            border-style: dashed;
            border-width: 2px;
            border-spacing: 2px;
            width: 100%;
            height: 126px;
        }

        .on-uploaded {
            width: 100%;
            border: 1px solid #43D9BB;
        }

        .securo-color {
            color: #43D9BB;
        }
    }


    .tabs-content {
        margin-left: 16px;
        margin-right: 16px;
        width: 100%;

        .MuiTabPanel-root {
            padding: 0px;
            padding-bottom: 44px;
        }

        .MuiGrid-root {
            margin: 0;
            width: 100%;
        }

        .tabs-config {
            text-transform: none;
        }

        .simple-select-helper {
            width: 100%;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            height: 44px;
        }
    }

    #kyc-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 24px;
        margin-bottom: 16px;
        border-bottom: 1px solid #EAECF0;
    }

    #profile-container {
        margin-bottom: 16px;

        .profile-icon-wrapper {
            padding-bottom: 25px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid #EAECF0;
            gap: 20px;

            .text-marginalized {
                margin-bottom: 20px;
            }

            .user-profile {
                width: 64px;
                height: 64px;
            }

            .profile-drag-and-drop-container {
                cursor: pointer;
                display: flex;
                height: 126px;
                width: 100%;
                background: #FFFFFF;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding: 16px 24px;
                border-radius: 8px;
                border: 1px dashed #EAECF0;

                .info-wrap {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;

                    .icon-wrap {
                        width: 40px;
                        height: 40px;
                        background-color: #F9FAFB;
                        border-radius: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .text-special {
                        color: #43D9BB;
                    }
                }

            }

            .user-profile-action {
                width: 100%;
                height: 100%;
                padding: 8px;
                display: flex;
                flex-direction: column;
                // margin-left: 8px;
                justify-content: center;

                .name-label {
                    margin: 16px 8px;
                    font-size: 18px;
                    font-weight: 700;
                }

                .upload-button {
                    background: rgba(118, 209, 191, 1);
                    color: #ffffff;
                    border-radius: 10px;
                }

                .remove-button {
                    margin-left: 8px;
                    background-color: none;
                    border-radius: 10px;
                    border: 1px solid rgba(204, 204, 204, 1);
                    color: rgba(204, 204, 204, 1);
                }
            }
        }

        .content-wrapper {
            padding: 16px;
        }
    }

    .root-profile {
        padding-left: 0px;
        padding-right: 0px;

        .about-text {
            padding-bottom: 20px;
        }
    }

    .about-tabs {
        width: 100%;
        border-bottom: 1px solid #EAECF0;
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
    }

    .on-updated-container {
        display: flex;
        background: #F6FEF9;
        border: 1px solid #6CE9A6;
        border-radius: 8px;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        .success-msg {
            display: flex;
            gap: 10px;
        }

        .close-icon {
            cursor: pointer;
        }
    }

    .section-one {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid #EAECF0;
        gap: 20px;

        .top {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 20px;
        }

        .bottom {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 20px;
        }
    }

    .section-two {
        padding-top: 20px;
        padding-bottom: 24px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid #EAECF0;

        .street-address {
            margin-top: 20px;
        }

        .street-origin {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .top {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 20px;
            }

            .bottom {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 20px;
            }
        }

    }

    #action-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 35px;
        gap: 12px;
        margin-top: 16px;

        .cancel-changes-button {
            width: 50%;
            background: #FFFFFF;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 10px;
            min-width: 79px;
            text-transform: none;
        }

        .save-changes-button {
            width: 50%;
            padding: 12px 16px;
            background: #43D9BB;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 10px;
            min-width: 79px;
            text-transform: none;
        }

        .discard-button {
            padding: 12px 16px;
            background: none;
            border-radius: 10px;
            font-weight: 700;
            color: #76D1BF;
            border: 2px solid #76D1BF;
            min-width: 180px;
        }
    }

    #input-container {
        display: flex;
        flex-direction: column;
        width: 50%;

        .upper-label {
            margin-bottom: 6px;

            &.special {
                color: rgba(158, 158, 158, 1);
            }

            .aesterisk {
                color: red;
            }
        }

        .shorter-input {
            width: 100%;

            .MuiOutlinedInput-root {
                border-radius: 10px;
                box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.05);
            }
        }

        .full-width-textfield {
            .MuiOutlinedInput-root {
                border-radius: 10px;
            }
        }

        .MuiOutlinedInput-root.Mui-disabled {
            .MuiOutlinedInput-input.Mui-disabled {
                background: rgba(204, 204, 204, 0.5);
                border-radius: 10px;
            }
        }

        .disabled {
            color: rgba(204, 204, 204, 0.5);
        }

        .password-form-input {
            width: 100%;

            .MuiOutlinedInput-root {
                height: 44px;
                border-radius: 8px;
                box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.05);

                .MuiInputBase-input {
                    background-color: transparent;
                }
            }
        }

        .date-picker {
            width: 100%;
            position: relative;

            .MuiOutlinedInput-root {
                padding-right: 0px;
                border-radius: 10px;
            }

            .MuiInputAdornment-root {
                position: absolute;
                right: 0;
                padding-right: 16px;
            }
        }

        .selection-options {
            border-radius: 10px;
        }
    }

}

.main-drag-and-drop-container {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 100%;
    background: #FFFFFF;

    @media only screen and (min-width: 720px) {
        height: 126px;
    }

    @media only screen and (max-width: 720px) {
        height: 96px;
    }

    // padding: 16px 24px;

    .root-box {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;

        border-radius: 8px;
        padding: 15px;


        .drop-box {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;

            .allow-cursor {
                cursor: pointer;
                width: 100%;
            }

            .icon {
                display: flex;

                @media only screen and (max-width: 720px) {
                    justify-content: flex-start;
                    align-items: center;
                    width: 10%;
                }

                height: 100%;

                .bound-icon {
                    width: 32px;
                    height: 32px;
                    border-radius: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 0px;
                }

                .on-uploaded {
                    background: #D1FADF;
                }
            }

            .file-details {
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
                // flex-grow: 1;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                height: 100%;
                width: 90%;

                @media only screen and (max-width: 720px) {
                    // width: 50%;
                    max-width: 70%;
                    overflow: hidden;
                }

                .file-name {
                    margin-top: 4px;

                    @media only screen and (max-width: 720px) {
                        padding-right: 20px;
                        width: 20px;
                    }

                    .wrapped-text {
                        @media only screen and (min-width: 720px) {
                            word-break: break-all;
                            overflow: hidden;
                            max-height: 50px;
                        }

                        @media only screen and (max-width: 720px) {
                            white-space: nowrap;
                            max-height: 50px;
                        }
                    }
                }

                .progress {
                    @media only screen and (min-width: 720px) {
                        width: 100%;
                    }

                    @media only screen and (max-width: 720px) {
                        width: 80%;
                    }

                    display: flex;
                    flex-grow: 1;
                    justify-content: flex-end;
                    align-items: flex-end;
                    margin-bottom: 4px;
                    gap: 10px;

                    .bar {
                        margin-top: 2px;
                        width: 90%;
                        height: 8px;
                        background: #43D9BB;
                        border-radius: 10px;
                    }

                    .text {
                        width: 10%;
                    }
                }

            }

            .action-container {
                display: flex;
                height: 100%;
                align-items: flex-start;
                gap: 6px;

                @media only screen and (max-width: 720px) {
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
            }

            .text-item {
                padding-left: 24px;
                width: 80%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: flex-start;
                overflow: hidden;

                .progress {
                    width: 100%;
                    display: flex;
                    flex-grow: 1;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 10px;

                    .bar {
                        margin-top: 2px;
                        width: 90%;
                        height: 8px;
                        background: #43D9BB;
                        border-radius: 10px;
                    }

                    .text {
                        width: 10%;
                    }
                }
            }

            .icon-wrapper-none {
                background: #F2F4F7;
                border: 6px solid #F9FAFB;
                border-radius: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;

                img {
                    width: 14px;
                    height: 14px;
                }
            }

            .text-item-empty {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                .guide-info {
                    display: flex;
                }
            }

            .wrapped-on-uploaded {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;

                .guide-info {
                    display: flex;
                }
            }
        }

        .action-item {
            width: 15%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            .icons-wrap {
                display: flex;
                align-items: center;
                gap: 8px;

                img {
                    height: 19px;
                    cursor: pointer;
                }
            }

        }
    }

    .on-default {
        justify-content: center;
        border: 1px dashed #EAECF0;
        border-style: dashed;
        border-width: 2px;
        border-spacing: 2px;

        @media only screen and (min-width: 720px) {
            width: 100%;
        }

        @media only screen and (max-width: 720px) {
            width: 100%;
        }

        height: 100%;
    }

    .on-uploaded {
        @media only screen and (min-width: 720px) {
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: 720px) {
            width: 100%;
        }

        border: 1px solid #43D9BB;
    }
}