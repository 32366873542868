.DEFAULT-transaction-txtype {
    @apply text-sm font-semibold text-gray-900;
}

.DARK-transaction-txtype {
    @apply text-sm font-semibold text-gray-500;
}

.DEFAULT-transaction-info {
    @apply text-sm font-semibold;
}

.DARK-transaction-info {
    @apply text-sm font-semibold text-white;
}