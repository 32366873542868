.DARK-account {
    .MuiTabs-root, .about-tabs, .profile-icon-wrapper, .section-one, .section-two, #kyc-container, .form-container {
        border-bottom: 1px solid #28303F !important;
    }
    .tabs-config {
        @apply text-gray-500;
        &.Mui-selected {
            color: #43D9BB;
        }
    }
    .MuiTabs-indicator {
        background-color: #43D9BB;
    }
    .card-title {
        @apply text-white #{!important};
    }
    .divider {
        margin-top: 20px;
        margin-bottom: 24px;
        background: #28303F;
    }

    .highlights, .about-text {
        @apply text-white;
    }

    .upper-label, .passport-name, .national-id {
        @apply text-gray-500 #{!important};
    }
    .MuiFormHelperText-root{
        margin-block-start: 6px;
        margin-left: 1px;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }
    .MuiOutlinedInput-root {
        border-radius: 8px;
        border: 1px solid #28303F !important;
        background: rgba(0, 0, 0, 0.2);
    }
    .MuiOutlinedInput-root:focus-within {
        background: linear-gradient(#090B11, #090B11) padding-box,
        linear-gradient(to right, #43D9BB 0%, #0299ED 84.67%) border-box;
        border-radius: 10px;
        border: 1px solid transparent !important; 
    }
    .MuiInputBase-input {
        padding: 8.5px 14px !important;
        background: none !important;
        border-radius: 10px;
        // border: 1px solid transparent !important;
        color: #FFFFFF;
    }
    .Mui-disabled {
        background: rgba(0, 0, 0, 0.1) !important;
        @apply text-gray-500;
    }
    
    .main-drag-and-drop-container, .kyc-drag-and-drop-container {
        background: transparent !important;

        .on-default {
            border: 1px dashed #28303F !important;
            border-width: 2px !important;
            border-spacing: 2px !important;
        }
        .securo-color {
            color: #43D9BB;
        }
        .progress {
            .text {
                @apply text-gray-500;
            }
        }
    }

    .cancel-changes-button {
        background: rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #28303F !important;
        @apply text-white #{!important};
        span {
            @apply text-white #{!important};
        }

        &:hover {
            background: rgb(129, 0, 0) !important;
        }
    }

    .save-changes-button {
        background: linear-gradient(90deg, #43D9BB 0%, #0299ED 84.67%) !important;
        border: none ;
        @apply text-white #{!important};
        height: 40px !important;
    }
}