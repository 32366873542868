// .MuiInputAdornment-root {
//     margin: 0px;

//     .MuiIconButton-root {
//         padding: 16px;
//         background-color: #FFFFFF;
//     }
// }

@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (min-width: 720px) {
    .main-page-wrapper {
        width: 100%;
        height: 100vh;

        .form-wrap-upper {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: 90%;

            .logo-wrapper {
                margin-top: 147px;
                margin-bottom: 38px;
            }
            .divider {
                margin-bottom: 30px;
                width: 360px;
                height: 1px;
                border-bottom: 1px solid #43D9BB;
            }
            .intro-wrapper {
                gap: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 32px;
            }
            
            .wrapped-options {
                padding-bottom: 20px;
                width: 360px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .wrapped-remember-me {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
                .MuiCheckbox-root {
                    padding: 0;
                    color: #D0D5DD;
                }
                .Mui-checked {
                    color: #43D9BB;
                    ;
                }
            }

            .wrapped-input {
                width: 360px;
                .text-input-title {
                    margin-bottom: 6px;
                }
                .hide-adorment {
                    position: absolute;
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    padding: 16px 14px;
                    pointer-events: none;

                    img {
                        pointer-events: fill;
                        cursor: pointer;
                    }
                }
                .MuiOutlinedInput-root {
                    padding-left: 0px;
                    padding-right: 0px;
                }

                .MuiSvgIcon-root {
                    height: 18px;
                }

                .MuiInputBase-input {
                    box-sizing: border-box;
                    height: 44px;
                }
                .MuiInputBase-root {
                    box-sizing: border-box;
                    border-radius: 8px;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    background-color: #FFFFFF;
                }
                .MuiFormHelperText-root {
                    margin: 0;
                    padding-top: 6px;
                    padding-bottom: 0px;
                    height: auto;
                    line-height: 1.05rem;
                }

                .create-account {
                    display: flex;
                    justify-content: center;
                    span {
                        color: #43D9BB;
                        cursor: pointer;
                    }
                }
            }
            
            #login-container {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
            
            #login-title-container {
                padding: 16px;
            
                .SignIn {
                    font-size: 32px;
                }
            }
            
            #forget-password {
                cursor: pointer;
                color: #43D9BB !important;
                
            }


        }
        .about-wrapped-main {
            height: 10%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 32px;
        }
    }

}

.about-wrapped {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 32px;
}

#welcome-text {
    font-size: 64px;
    font-weight: 600;
    color: #2D433E;
    white-space: pre;
    margin-top: 24px;
    margin-bottom: 24px;
}

#welcome-desc {
    margin-top: 0px;
    font-weight: 400;
    color: #2E3836;
}

.on-error {
    padding-bottom: 20px;
}

.on-default {
    padding-bottom: 20px;
}

#login-container {
    width: 100%;
}

@media only screen and (max-width: 720px) {
    .main-page-wrapper {
        width: 100%;
        height: 100vh;

        .form-wrap-upper {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: 90%;

            .login-container {
                width: 100%;
                padding-left: 16px;
                padding-right: 16px;
            }

            .logo-wrapper {
                margin-top: 48px;
                margin-bottom: 38px;
            }
            .divider {
                margin-bottom: 30px;
                width: calc(100% - 24px);
                height: 1px;
                border-bottom: 1px solid #43D9BB;
            }
            .intro-wrapper {
                gap: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 32px;
            }
            
            .wrapped-options {
                padding-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .wrapped-remember-me {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
                .MuiCheckbox-root {
                    padding: 0;
                    color: #D0D5DD;
                }
                .Mui-checked {
                    color: #43D9BB;
                    ;
                }
            }

            .wrapped-input {
                
                .text-input-title {
                    margin-bottom: 6px;
                }
                .hide-adorment {
                    position: absolute;
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    padding: 16px 14px;
                    pointer-events: none;

                    img {
                        pointer-events: fill;
                        cursor: pointer;
                    }
                }
                .MuiOutlinedInput-root {
                    padding-left: 0px;
                    padding-right: 0px;
                }

                .MuiSvgIcon-root {
                    height: 18px;
                }
                .MuiButtonBase-root {
            
                }
                .MuiInputBase-input {
                    box-sizing: border-box;
                    height: 44px;
                }
                .MuiInputBase-root {
                    box-sizing: border-box;
                    border-radius: 8px;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    background-color: #FFFFFF;
                }
                .MuiFormHelperText-root {
                    margin: 0;
                    padding-top: 6px;
                    padding-bottom: 0px;
                    height: auto;
                    line-height: 1.05rem;
                }


                .btn-padding {
                    margin-bottom: 32px;
                    .btn {
                        height: 44px;
                        text-transform: none;
                    }
            
                    .on-default-state {
                        background: #AAF0E2;
                    }
                    .on-invalid-state {
                        background: #43D9BB;
                    }
            
                }
            
                .create-account {
                    display: flex;
                    justify-content: center;
                    span {
                        color: #43D9BB;
                        cursor: pointer;
                    }
                }
            }
            
            #login-container {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
            
            #login-title-container {
                padding: 16px;
            
                .SignIn {
                    font-size: 32px;
                }
            }
            
            #forget-password {
                cursor: pointer;
                color: #43D9BB !important;
                
            }


        }
        .about-wrapped-main {
            height: 10%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 20px;
        }
    }
    .about-wrapped {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 20px;
    }
}


// @media (max-width: 600px) {
//     .main-page-wrapper {
//         margin-top: 0px;
//         margin-left: 0px;
//     }

//     #welcome-text {
//         font-size: 24px;
//         text-align: center;
//         padding-right: 30px;
//     }

//     #login-container {
//         margin-left: auto;
//         margin-right: auto;
//         margin-bottom: 50px;
//         padding-right: 20px;
//         padding-left: 15px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }

//     #login-title-container {
//         padding: 16px;

//         .SignIn {
//             font-size: 25px;
//         }
//     }

//     #welcome-desc {
//         font-size: 14px;
//         text-align: center;
//         padding-right: 30px;
//     }

// }

// @media (min-width: 600px) and (max-width: 990px) {

//     .main-page-wrapper {
//         margin-top: 0px;
//         margin-left: 0px;
//         // margin-left: 74px;
//     }

//     #login-container {
//         margin-left: auto;
//         margin-right: auto;
//         margin-bottom: 50px;
//     }

//     #welcome-desc {
//         text-align: center;
//         padding-right: 70px;
//     }

//     #welcome-text {
//         font-size: 35px;
//         text-align: center;
//         padding-right: 70px;
//     }

// }

.card-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  font-family: "Inter";
}

.section-title {
	font-family: Inter;
	font-size: 16px;
	font-weight: 700;
  color: #76D1BF;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

