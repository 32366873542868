@media only screen and (max-width: 720px) {
    .securo-text-button {
        color: red;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 14px;
        min-width: 40px;
    }
}

@media only screen and (min-width: 720px) {
    .securo-text-button {
        color: red;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0.5rem 3rem;
        font-size: 14px;
        min-width: 40px;
    }
}