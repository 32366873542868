.developer-transaction-container {
    @media only screen and (max-width: 720px) {
        margin-left: 16px;
        margin-right: 16px;

        .filterBtn {
            @apply font-medium border-solid border border-gray-300 text-gray-700 h-10 bg-gray-25 rounded-lg;
            padding: 10px 16px;
            text-transform: none;
            width: 100%;
            height: 40px !important;

            &:hover {
                @apply bg-gray-100 shadow-none border-gray-300
            }

            background: #FFFFFF;
        }

        .mobile-flex {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;

            a {
                width: 100%;
            }
        }

        .exportBtn {
            display: flex;
            gap: 9.67px;
            @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 text-gray-800 font-medium;
            width: 105px;
            text-transform: none;
            height: 40px !important;

            @media only screen and (max-width: 720px) {
                width: 100%;
            }

            background: #FFFFFF;
        }
    }

    @media only screen and (min-width: 720px) {
        margin-left: 32px;
        margin-right: 32px;

        .filterBtn {}

        .exportBtn {
            margin-left: 6px;
            display: flex;
            gap: 9.67px;
            @apply border-solid border rounded-lg border-gray-300 h-10 bg-gray-25 text-gray-800 font-medium;
            width: 105px;
            text-transform: none;
            height: 40px !important;
            background: #FFFFFF;
        }

    }

    .transaction {
        @apply text-lg font-medium;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
    }

    .wrapped-status {
        width: 72px;
        height: 22px;
        padding: 2px 8px;
        text-align: center;
        border-radius: 16px;
    }

    .case-success {
        background: #ECFDF3;
    }

    .case-pending {
        background: #F2F4F7;
    }

    .case-failed {
        background: #FAD1D1;
    }
}

.transaction-details-container {
    width: 100%;

    @media only screen and (min-width: 720px) {
        margin-top: 132px;
        padding-left: 32px;
        padding-right: 32px;
    }

    @media only screen and (max-width: 720px) {
        padding-left: 16px;
        padding-right: 16px;

        .general-details {
            flex-direction: column;

            .on-responsive {
                padding-left: 0;
            }
        }

        .of-send-or-receive-wallet {
            flex-direction: column;

            .sender {
                border-right: none !important;
            }

            .receiver {
                margin-top: 16px;
                padding-top: 16px;
                flex-shrink: 0;
                border-width: 0;
                border-style: solid;
                border-color: rgba(0, 0, 0, 0.12);
                border-top-width: thin;
                padding-left: 0;
            }
        }
    }

    .transaction {
        display: flex;
        gap: 10px;
        @apply text-lg font-medium;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
    }

    .basic-details {

        .headings {
            @apply font-normal text-base text-gray-500;
        }

        .first-row {
            @apply font-semibold text-4xl;
        }

        .second-row {
            @apply font-medium text-base;
        }

        .third-row {
            @apply font-normal text-base;
        }
    }

    .timeline-container {

        .timeline {
            @apply font-medium text-lg;
        }

        .headings {
            @apply font-normal text-base
        }

        .sub-headings {
            @apply font-normal text-sm text-gray-500;

            a {
                color: #43D9BB;
            }
        }
    }

    .payment-details {

        .payment {
            @apply font-medium text-lg;
        }

        .headings {
            @apply font-normal text-base;
        }

        .sub-headings {
            @apply font-semibold text-sm;
        }
    }

    .api-log-container {
        @media only screen and (min-width: 720px) {
            padding-bottom: 50px;
        }

        .api-log {
            @apply font-medium text-lg;
        }

        @media only screen and (min-width: 720px) {
            padding-bottom: 150px;
        }
    }

}

.dev-transactions-root {

    width: 100%;

    .upper-panel {
        @media only screen and (max-width: 720px) {
            flex-direction: column;
        }

        margin-bottom: 18px;

        @media only screen and (max-width: 720px) {
            .responsive-group {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;

                .MuiButtonGroup-root {
                    width: 100%;
                }
            }
        }

        .btn {
            width: 97px;
            height: 40px;
            padding: 10px 16px;
        }

        .export-btn {
            @media only screen and (min-width: 720px) {
                margin-left: 16px;
            }

            width: 105px;
            height: 40px;
            padding: 10px 16px;
        }
    }

    .transactions-content {

        .transactions-table {
            position: relative;
            margin-top: 24px;


        }

        .padded-options {
            @media only screen and (min-width: 720px) {
                padding-bottom: 1500px;
            }
        }
    }

}