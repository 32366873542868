.developer-business-details-container {

    .business-details {
        @apply text-lg font-medium text-gray-900;
    }

    .subtitle {
        @apply text-sm font-normal text-gray-500;
        margin-top: 4px;
    }

    .groupButton {
        @apply font-bold border-solid border border-gray-300 text-gray-700 h-10 bg-gray-25 rounded-md capitalize p-5;

    }

    .groupButton:hover {
        // color: #eef2fb;
        @apply bg-gray-100 shadow-none;
    }

    .drag-and-drop-container {
        cursor: pointer;
        display: flex;
        height: 126px;
        width: 100%;
        background: #FFFFFF;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 16px 24px;
        border-radius: 8px;
        border: 1px dashed #EAECF0;


        .root-box {
            display: flex;
            justify-content: flex-start;
            flex-direction: row; 
    
            border: 1px solid #43D9BB;
            border-radius: 8px;
            padding: 15px;
    
            .drop-box {
                width: 85%;
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
        
                .allow-cursor {
                    cursor: pointer;
                }
                .icon {
                    display: flex;
                    width: 10%;
                    .bound-icon {
                        width: 32px;
                        height: 32px;
                        border-radius: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 0px;
                    }
        
                    .on-uploaded {
                        background: #D1FADF;
                    }
                }
                .text-item {
                    width: 90%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    align-items: flex-start;
                    overflow: hidden;
        
                    .progress {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        .bar {
                            margin-top: 2px;
                            width: 85%;
                            height: 8px;
                            background: #43D9BB;
                            border-radius: 10px;
                        }
                        .text {
                            width: 15%;
                        }
                    }
                }
    
                .icon-wrapper-none {
                    background: #F2F4F7;
                    border: 6px solid #F9FAFB;
                    border-radius: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
    
                    img {
                        width: 14px;
                        height: 14px;
                    }
                }
                .text-item-empty {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
    
                    .guide-info {
                        display: flex;
                    }
                }
            }
    
            .action-item {
                width: 15%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                .icons-wrap {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    img {
                        height: 19px;
                        cursor: pointer;
                    }
                }
    
            }
        }

        .on-default {
            border: none;
            // width: 520px;
            height: 126px;
        }

        .on-uploaded {
            // width: 520px;
            height: 126px;
            border: 1px solid #43D9BB;
        }

        .securo-color {
            color: #43D9BB;
        }
    }

}

.business-details-grid {
    width: 100%;
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 720px) {
        flex-direction: column;
        margin: 0;
    }

    .form-panel {
        @media only screen and (min-width: 720px) {
            max-width: 75%;
            padding-left: 0;
            width: 75%;
        }

        @media only screen and (max-width: 720px) {
            max-width: 100%;
            padding: 0;
        }
    }

    .preview-panel {
        @media only screen and (min-width: 720px) {
            margin-left: 16px  ;
            max-width: 75%;
            .subheadings {
                margin-bottom: 12px;
            }
        }

        @media only screen and (max-width: 720px) {
            max-width: 100%;
            .subheadings {
                @apply font-medium text-sm text-gray-700;
                margin-bottom: 12px;
            }
        }

        .rounded-full {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: auto;
                height: 50px;
            }
        }
    }
}

.bd-root-box {
    @media only screen and (min-width: 720px) {
        margin-right: 32px;
        margin-left: 32px;
    }

    @media only screen and (max-width: 720px) {
        padding-right: 16px;
        padding-left: 16px;
    }

    width: 100%;

    .divider-spacer {
        margin-top: 20px;
        margin-bottom: 24px;
    }

    .divider-spacer-last {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .grid-top {
        @media only screen and (min-width: 720px) {
            margin-top: 162px;
        }

        @media only screen and (max-width: 720px) {
            margin-top: 20px;
        }
    }

    .bd-logo {
        margin-top: 12px;
        margin-bottom: 20px;
        display: flex;

        @media only screen and (min-width: 720px) {
            flex-direction: row;
        }

        @media only screen and (max-width: 720px) {
            flex-direction: column;
        }

        gap: 20px;

        .bounded-logo {
            min-width: 126px;

            .img-container {
                overflow: hidden;
                border-radius: 200px;
                width: 126px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: auto;
                    height: 126px;
                }
            }

        }

        .drag-and-drop {}
    }

    .input-text {
        margin-bottom: 4px;
    }

    .input-field {
        .MuiOutlinedInput-root {
            border-radius: 8px;
        }
    }

    .input-description {
        .MuiOutlinedInput-root {
            border-radius: 8px;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    .text-field {
        .MuiOutlinedInput-notchedOutline {
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
        }
    }

    .characters-left {
        margin-top: 6px;
    }
}