.loader-load {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // backdrop-filter: contrast(0.8);
    // border-radius: 12px;
    img {
        margin-bottom: 20px;
        width: 100px;
        animation: opacity 2s ease-in-out infinite;
    }

    .align-loader {
        margin-bottom: 47px;
    }
}

@keyframes opacity {
    0% { opacity: .6; }
    50% { opacity: .2; }
    100% { opacity: .6; }
}