.business-drag-and-drop-container {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 100%;

    // padding: 16px 24px;

    .root-box {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 15px;


        .drop-box {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;

            .allow-cursor {
                cursor: pointer;
                width: 100%;
            }

            .icon {
                display: flex;

                @media only screen and (max-width: 720px) {
                    justify-content: center;
                    align-items: center;
                }

                height: 100%;

                .bound-icon {
                    width: 32px;
                    height: 32px;
                    border-radius: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 0px;
                }

                .on-uploaded {
                    background: #D1FADF;
                }
            }

            .file-details {
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
                // flex-grow: 1;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                height: 100%;
                width: 90%;

                @media only screen and (max-width: 720px) {
                    // width: 50%;
                    max-width: 70%;
                    overflow: hidden;
                }

                .file-name {
                    margin-top: 4px;

                    @media only screen and (max-width: 720px) {
                        padding-right: 20px;
                        width: 20px;
                    }

                    .wrapped-text {
                        @media only screen and (min-width: 720px) {
                            word-break: break-all;
                            overflow: hidden;
                            max-height: 50px;
                        }

                        @media only screen and (max-width: 720px) {
                            white-space: nowrap;
                            max-height: 50px;
                        }
                    }
                }

                .progress {
                    @media only screen and (min-width: 720px) {
                        width: 100%;
                    }

                    @media only screen and (max-width: 720px) {
                        width: 80%;
                    }

                    display: flex;
                    flex-grow: 1;
                    justify-content: flex-start;
                    align-items: flex-end;
                    margin-bottom: 4px;
                    gap: 10px;

                    .bar {
                        margin-top: 2px;
                        width: 90%;
                        height: 8px;
                        background: #43D9BB;
                        border-radius: 10px;
                    }

                    .bar-one-new-state {
                        margin-top: 2px;
                        width: 8px;
                        height: 8px;
                        background: #43D9BB;
                        border-radius: 10px;
                        animation: resizeBar 3s ease-in;
                        animation-fill-mode: forwards;
                    }

                    // @keyframes resizeBar {
                    //     0% {
                    //         width: 8px;
                    //     }
                    //     20% {
                    //         width: 20%;
                    //     }
                    //     100% {
                    //         width: 100%;
                    //     }
                    // }

                    .text {
                        width: 10%;
                    }
                }

                .progress-is-upload {
                    @media only screen and (min-width: 720px) {
                        width: 100%;
                    }

                    @media only screen and (max-width: 720px) {
                        width: 80%;
                    }

                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 4px;
                    gap: 4px;

                    .circular-progress {
                        width: 30px !important;
                        height: 30px !important;
                    }
                }

            }

            .action-container {
                flex-grow: 1;
                display: flex;
                height: 100%;
                align-items: flex-start;
                justify-content: flex-end;
                gap: 6px;

                @media only screen and (max-width: 720px) {
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
            }

            .text-item {

                padding-left: 24px;
                width: 80%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: flex-start;
                overflow: hidden;

                .progress {
                    width: 100%;
                    display: flex;
                    flex-grow: 1;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 10px;

                    .bar {
                        margin-top: 2px;
                        width: 90%;
                        height: 8px;
                        background: #43D9BB;
                        border-radius: 10px;
                    }

                    .text {
                        width: 10%;
                    }
                }
            }

            .icon-wrapper-none {
                background: #F2F4F7;
                border: 6px solid #F9FAFB;
                border-radius: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;

                img {
                    width: 14px;
                    height: 14px;
                }
            }

            .text-item-empty {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                .guide-info {
                    display: flex;
                    margin-top: 10px;
                }
            }

            .wrapped-on-uploaded {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                overflow: hidden;

                .guide-info {
                    display: flex;
                }
            }
        }

        .action-item {
            width: 15%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            .icons-wrap {
                display: flex;
                align-items: center;
                gap: 8px;

                img {
                    height: 19px;
                    cursor: pointer;
                }
            }

        }
    }

    .on-default {
        justify-content: center;
        border: 1px dashed #EAECF0;
        border-style: dashed;
        border-width: 2px;
        border-spacing: 2px;

        @media only screen and (min-width: 720px) {
            width: 100%;
        }

        @media only screen and (max-width: 720px) {
            width: 100%;
        }

        height: 100%;
    }

    .on-uploaded {
        @media only screen and (min-width: 720px) {
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: 720px) {
            width: 100%;
        }

        border: 1px solid #43D9BB;
    }
}