.main-card {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 32px;
    margin-bottom: 4px;
    justify-content: space-between;

    @media only screen and (max-width: 720px) {
        flex-direction: column;
        gap: 8px;

        .create-new-action {
            width: 100%;
            button {
                width: 100%;
            }
        }
    }
}