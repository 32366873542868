.referral {
  .input-field {
  
    .MuiOutlinedInput-root {
      height: 44px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
  
      .MuiOutlinedInput-input {
        background-color: #FFFFFF;
      }
    }
  }
}