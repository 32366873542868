.inject-on-switched {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 32px;
    padding-bottom: 40px;
    backdrop-filter: blur(1px);
    z-index: 1000;
    .conceal {
        width: 80px;
    }
}